import BaseConfig from "./config.base" 
import MapperConfig from "./config.mapper"
 
 
let ExtraConfig = MapperConfig
 
// Verificar si usamos un BaseConfig..., puede ser para lo que no cambie de un entorno a otro.
const Config = { ...BaseConfig, ...ExtraConfig }


export default Config;
