import axiosMockAdapter from 'src/utils/mock';
import type { Tag } from 'src/models/tag';
import randomId from 'src/utils/randomId';
const urlMatch = new RegExp(/^\/api\/tags\//); 


let tags: Tag[] =  [
    {
      "isTagVisible": true,
      "tagColor": "#000000",
      "tagName": "MARZO",
      "id": "0sr2T13LLgSv70vH3KF6"
    },
    {
      "isTagVisible": false,
      "tagColor": "#1fe022",
      "tagName": "MALE",
      "id": "8HHBLP2OIpFaL36sK5uF"
    },
    {
      "isTagVisible": false,
      "tagColor": "#1fe022",
      "tagName": "AGOSTO",
      "id": "btj2McNknlZ3Rr68aeaP"
    },
    {
      "isTagVisible": false,
      "tagColor": "#9f2828",
      "tagName": "SEPTEIMBRE",
      "id": "cRUSu1PtyY8HUkQNOKfY"
    },
    {
      "isTagVisible": false,
      "tagColor": "#1fe022",
      "tagName": "OCTUBRE",
      "id": "iw4bQQPJNrm68Gnv2txi"
    },
    {
      "isTagVisible": true,
      "tagColor": "#e4c70c",
      "tagName": "VIP",
      "id": "v2UW8fXRZhMlKgKAGRHf"
    },
    {
      "isTagVisible": true,
      "tagColor": "#1fe022",
      "tagName": "VERIFICADO",
      "id": "vljlZ38kV0qalFulP3Ax"
    },
    {
      "isTagVisible": false,
      "tagColor": "#1fe022",
      "tagName": "ABRIL",
      "id": "xVqMhLkz3iy3M5A8vKz7"
    }
  ];

axiosMockAdapter.onGet('/api/tags').reply(200, { tags });

axiosMockAdapter.onDelete(urlMatch).reply((config) => {
  const tagDelete = JSON.parse(config.data);
  const index = tags.findIndex((tag) => tag.id === tagDelete.id);

  if (index !== -1) {
    tags.splice(index, 1);
    return [200, { message: 'Tag eliminada correctamente' }, { status: 200, statusText: 'OK' }];
  } else {
    return [404, { error: 'Tag no encontrada' }, { status: 404, statusText: 'Not Found', data: { message: 'Tag no encontrada' } }];
  }
})

 axiosMockAdapter.onPost('/api/tags').reply((config) => {
  const tagData = JSON.parse(config.data);
  tagData.id = randomId();
  tags.unshift(tagData);
  return [201, tagData];
}); 

axiosMockAdapter.onPut(urlMatch).reply((config) => {
  
  const tagData = JSON.parse(config.data);
  const index = tags.findIndex((tag) => tag.id === tagData.id);
  if (index === -1) {
      return [404, { error: 'Tag no encontrada' }, { status: 404, statusText: 'Not Found', data: { message: 'Tag no encontrada' } }]
  }
  tags[index] = tagData;
  return [200, tagData];
});


