import { Box, Tooltip, TooltipProps, tooltipClasses, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';
import LogoIcon from 'src/common/assets/images/logoWhite.png';
import configMapper from 'src/config/config.mapper';

const LogoWrapper = styled(Link)(({ theme }) => `
    color: ${theme.palette.text.primary};
    display: flex;
    text-decoration: none;
    width: 53px;
    margin: 0 auto;
    font-weight: ${theme.typography.fontWeightBold};
  `
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow: '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo() {
  const { toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  return (
    <TooltipWrapper title={`${configMapper.APP_DISPLAY_NAME} Backoffice`} arrow>
      <LogoWrapper to="/dashboard/management/events/list" onClick={closeSidebar}>
        <Box
          component="img"
          src={LogoIcon}
          alt="Logo"
          sx={{
            width: "200px",
            height: 'auto',
            display: 'block',
            margin: '0 auto',
            left: -10,
          }}
        />
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
