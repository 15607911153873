import axiosMockAdapter from 'src/utils/mock';
import type { Event } from 'src/models/event';
import type { Tag } from 'src/models/tag';
import type { Statistic, StatisticsDetail } from 'src/models/statistic';

let events: Event[] = [
  {
    "evtImgPath": "events/1672262749120_imagen.png",
    "evtPlace": "Keops",
    "evtSellVerify": false,
    "evtTags": [
      {
        "isTagVisible": false,
        "tagColor": "#1fe022",
        "id": "btj2McNknlZ3Rr68aeaP",
        "tagName": "AGOSTO"
      },
      {
        "isTagVisible": true,
        "tagColor": "#e4c70c",
        "id": "v2UW8fXRZhMlKgKAGRHf",
        "tagName": "VIP"
      }
    ],
    "evtProducer": "1",
    "evtCreateUser": "lautaro.villarroel@vortex-it.com",
    "evtLastUpdateUser": "superadm@mailinator.com",
    "evtInitDate": {
      "_seconds": 1672086180,
      "_nanoseconds": 0
    },
    "evtPlaceAddress": "Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina",
    "evtTitle": "Pruebas Ger 4",
    "evtLastUpdate": {
      "_seconds": 1673450936,
      "_nanoseconds": 890000000
    },
    "evtImg": "https://firebasestorage.googleapis.com/v0/b/keops-dev.appspot.com/o/events%2F1672262749120_imagen.png?alt=media&token=cce5c256-4a53-47b3-bb2d-0c4cc08e5e71",
    "evtImgName": "imagen.png",
    "evtPlaceId": "lMT7mH5IqQ6ZtRQtWWkF",
    "evtCreated": {
      "_seconds": 1673450936,
      "_nanoseconds": 890000000
    },
    "evtConditions": "Eliminar tras las pruebas",
    "evtDateTime": {
      "_seconds": 1704078000,
      "_nanoseconds": 0
    },
    "evtSellRrppEnable": true,
    "evtName": "Pruebas Ger 4",
    "evtEndDate": {
      "_seconds": 1704054180,
      "_nanoseconds": 0
    },
    "evtCoordinates": {
      "_latitude": -31.4024536,
      "_longitude": -64.46538029999999
    },
    "evtDescription": "Pruebas de tickets por generar en el listado de tickets",
    "evtState": "active",
    "id": "ruzuWskxBttP8lwJ52tA"
  },
  {
    "evtImgPath": "events/1672262749120_imagen.png",
    "evtPlace": "Keops",
    "evtSellVerify": false,
    "evtTags": [
      {
        "isTagVisible": false,
        "tagColor": "#1fe022",
        "id": "btj2McNknlZ3Rr68aeaP",
        "tagName": "AGOSTO"
      }
    ],
    "evtProducer": "1",
    "evtCreateUser": "superadm@mailinator.com",
    "evtLastUpdateUser": "superadm@mailinator.com",
    "evtPlaceAddress": "Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina",
    "evtInitDate": {
      "_seconds": 1672086180,
      "_nanoseconds": 0
    },
    "evtTitle": "Pruebas Ger 2",
    "evtImg": "https://firebasestorage.googleapis.com/v0/b/keops-dev.appspot.com/o/events%2F1672262749120_imagen.png?alt=media&token=cce5c256-4a53-47b3-bb2d-0c4cc08e5e71",
    "evtImgName": "imagen.png",
    "evtPlaceId": "lMT7mH5IqQ6ZtRQtWWkF",
    "evtConditions": "Eliminar tras las pruebas",
    "evtCreated": {
      "_seconds": 1673449630,
      "_nanoseconds": 425000000
    },
    "evtDateTime": {
      "_seconds": 1704078000,
      "_nanoseconds": 0
    },
    "evtSellRrppEnable": true,
    "evtName": "Pruebas Ger 2",
    "evtEndDate": {
      "_seconds": 1704054180,
      "_nanoseconds": 0
    },
    "evtCoordinates": {
      "_latitude": -31.4024536,
      "_longitude": -64.46538029999999
    },
    "evtState": "active",
    "evtDescription": "Pruebas de tickets por generar en el listado de ticket",
    "evtLastUpdate": {
      "_seconds": 1675103907,
      "_nanoseconds": 174000000
    },
    "id": "ofFvEWNaLItqy5uYDLxx"
  },
  {
    "evtImgPath": "events/1672259128095_keopswhite.png",
    "evtPlace": "Keops",
    "evtTags": [],
    "evtSellVerify": false,
    "evtProducer": "1",
    "evtCreateUser": "superadm@mailinator.com",
    "evtLastUpdateUser": "superadm@mailinator.com",
    "evtPlaceAddress": "Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina",
    "evtInitDate": {
      "_seconds": 1672086180,
      "_nanoseconds": 0
    },
    "evtTitle": "BENEFICIOS - CANJES",
    "evtImg": "https://firebasestorage.googleapis.com/v0/b/keops-dev.appspot.com/o/events%2F1672259128095_keopswhite.png?alt=media&token=a99adc2b-7532-4632-991f-842dcc6ad7e2",
    "evtPlaceId": "lMT7mH5IqQ6ZtRQtWWkF",
    "evtImgName": "keopswhite.png",
    "evtCreated": {
      "_seconds": 1672259132,
      "_nanoseconds": 256000000
    },
    "evtConditions": "BENEFICIOS DE KEOPS APP - SOLO DISPONIBLE PARA EL TITULAR DEL TICKET - NO VALIDO PARA CANJEAR POR DINERO EN EFECTIVO - PARA USO EXCLUSIVO A TRAVES DE LA APLICACIÓN",
    "evtDateTime": {
      "_seconds": 1704054120,
      "_nanoseconds": 0
    },
    "evtSellRrppEnable": false,
    "evtName": "BENEFICIOS - CANJES",
    "evtEndDate": {
      "_seconds": 1704054180,
      "_nanoseconds": 0
    },
    "evtCoordinates": {
      "_latitude": -31.4024536,
      "_longitude": -64.46538029999999
    },
    "evtDescription": "BENEFICIOS - CANJES",
    "evtLastUpdate": {
      "_seconds": 1675110281,
      "_nanoseconds": 641000000
    },
    "evtState": "active",
    "id": "5K6g83cpHlQO8oQ0HMbN"
  },
  {
    "evtPlace": "Keops",
    "evtTags": [],
    "evtSellVerify": false,
    "evtProducer": "1",
    "evtCreateUser": "superadm@mailinator.com",
    "evtLastUpdateUser": "superadm@mailinator.com",
    "evtPlaceAddress": "Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina",
    "evtInitDate": {
      "_seconds": 1688688480,
      "_nanoseconds": 0
    },
    "evtTitle": "DI CHIARA BROTHERS EN KEOPS!",
    "evtPlaceId": "lMT7mH5IqQ6ZtRQtWWkF",
    "evtConditions": "Evento para mayores de 18 años. \nObligatorio presentar DNI para ingresar (físico o Mi Argentina).\nComprando tu anticipada el ingreso es sin limite de horario.\nIngreso con prioridad\nLas entradas no tienen devolución.",
    "evtCreated": {
      "_seconds": 1688688669,
      "_nanoseconds": 125000000
    },
    "evtDateTime": {
      "_seconds": 1689476340,
      "_nanoseconds": 0
    },
    "evtSellRrppEnable": true,
    "evtName": "DI CHIARA BROTHERS EN KEOPS!",
    "evtCoordinates": {
      "_latitude": -31.4024536,
      "_longitude": -64.46538029999999
    },
    "evtDescription": "Desde Italia a la piramide sin escala! Djs exclusivos de Keops Disco. Warm Up [Luciano Bertorello - Exe Groov]",
    "evtState": "active",
    "evtImgPath": "events/1688771078648_image 2.jpg",
    "evtImg": "https://firebasestorage.googleapis.com/v0/b/keops-dev.appspot.com/o/events%2F1688771078648_image%202.jpg?alt=media&token=040fffb4-8870-4193-ba17-9e4c0873476e",
    "evtImgName": "image 2.jpg",
    "evtEndDate": {
      "_seconds": 1690797600,
      "_nanoseconds": 0
    },
    "evtLastUpdate": {
      "_seconds": 1690042715,
      "_nanoseconds": 786000000
    },
    "id": "xVYVQ9QjhiV9qJqtwzvn"
  },
  {
    "evtImgPath": "events/1672686807210_keopsBlack.png",
    "evtPlace": "Keops",
    "evtSellVerify": false,
    "evtProducer": "1",
    "evtCreateUser": "superadm@mailinator.com",
    "evtLastUpdateUser": "superadm@mailinator.com",
    "evtPlaceAddress": "Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina",
    "evtInitDate": {
      "_seconds": 1672600320,
      "_nanoseconds": 0
    },
    "evtTitle": "Keops Enero 7",
    "evtImg": "https://firebasestorage.googleapis.com/v0/b/keops-dev.appspot.com/o/events%2F1672686807210_keopsBlack.png?alt=media&token=6bb7bdab-9873-43f5-9244-8bae08653fd5",
    "evtImgName": "keopsBlack.png",
    "evtPlaceId": "lMT7mH5IqQ6ZtRQtWWkF",
    "evtCreated": {
      "_seconds": 1672686810,
      "_nanoseconds": 560000000
    },
    "evtConditions": "T y C",
    "evtDateTime": {
      "_seconds": 1685560260,
      "_nanoseconds": 0
    },
    "evtSellRrppEnable": true,
    "evtName": "Keops Enero 7",
    "evtCoordinates": {
      "_latitude": -31.4024536,
      "_longitude": -64.46538029999999
    },
    "evtDescription": "Keops Enero 7",
    "evtEndDate": {
      "_seconds": 1685661120,
      "_nanoseconds": 0
    },
    "evtState": "active",
    "evtTags": [],
    "evtLastUpdate": {
      "_seconds": 1675111683,
      "_nanoseconds": 747000000
    },
    "id": "1zuCtys6cjN63HL70bDJ"
  },
  {
    "evtImgPath": "events/1675372351170_evento-defaut.jpg",
    "evtPlace": "Keops",
    "evtTags": [],
    "evtSellVerify": false,
    "evtProducer": "1",
    "evtCreateUser": "superadm@mailinator.com",
    "evtLastUpdateUser": "superadm@mailinator.com",
    "evtInitDate": {
      "_seconds": 1675285920,
      "_nanoseconds": 0
    },
    "evtPlaceAddress": "Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina",
    "evtTitle": "evento prueba",
    "evtLastUpdate": {
      "_seconds": 1675372355,
      "_nanoseconds": 929000000
    },
    "evtImg": "https://firebasestorage.googleapis.com/v0/b/keops-dev.appspot.com/o/events%2F1675372351170_evento-defaut.jpg?alt=media&token=ac35784c-3a52-4142-a166-e0baaa531147",
    "evtPlaceId": "lMT7mH5IqQ6ZtRQtWWkF",
    "evtImgName": "evento-defaut.jpg",
    "evtConditions": "t y c",
    "evtCreated": {
      "_seconds": 1675372355,
      "_nanoseconds": 929000000
    },
    "evtDateTime": {
      "_seconds": 1684530720,
      "_nanoseconds": 0
    },
    "evtSellRrppEnable": true,
    "evtName": "evento prueba",
    "evtEndDate": {
      "_seconds": 1684530720,
      "_nanoseconds": 0
    },
    "evtCoordinates": {
      "_latitude": -31.4024536,
      "_longitude": -64.46538029999999
    },
    "evtDescription": "evento prueba",
    "evtState": "active",
    "id": "ytYmB8FS67BK2nQcIoyw"
  },
  {
    "evtPlace": "Keops",
    "evtProducer": "1",
    "evtCreateUser": "superadm@mailinator.com",
    "evtLastUpdateUser": "superadm@mailinator.com",
    "evtPlaceAddress": "Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina",
    "evtInitDate": {
      "_seconds": 1670090520,
      "_nanoseconds": 0
    },
    "evtPlaceId": "lMT7mH5IqQ6ZtRQtWWkF",
    "evtCreated": {
      "_seconds": 1670176950,
      "_nanoseconds": 808000000
    },
    "evtCoordinates": {
      "_latitude": -31.4024536,
      "_longitude": -64.46538029999999
    },
    "evtSellRrppEnable": true,
    "evtSellVerify": false,
    "evtImgPath": "events/1672166113013_KEOPS año nuevo 31-12-22.jpg",
    "evtConditions": "Evento para mayores de 16 años. \nObligatorio presentar DNI para ingresar (físico o Mi Argentina).\nComprando tu anticipada el ingreso es sin limite de horario.\nIngreso con prioridad.",
    "evtName": "KEOPS Año Nuevo - Open Doors 1AM",
    "evtDescription": "Año nuevo en KEOPS. Apertura 1am. Recepción by Mumm. Nueva perspectiva. Reapertura nuevo ingreso. DJ AGUS JUVANY.",
    "evtTitle": "KEOPS Año Nuevo - Open Doors 1AM",
    "evtImg": "https://firebasestorage.googleapis.com/v0/b/keops-dev.appspot.com/o/events%2F1672166113013_KEOPS%20an%CC%83o%20nuevo%2031-12-22.jpg?alt=media&token=9ece596c-a793-46dc-8538-7b043f39bd6f",
    "evtImgName": "KEOPS año nuevo 31-12-22.jpg",
    "evtDateTime": {
      "_seconds": 1672541940,
      "_nanoseconds": 0
    },
    "evtEndDate": {
      "_seconds": 1675188120,
      "_nanoseconds": 0
    },
    "evtTags": [
      {
        "isTagVisible": true,
        "tagColor": "#e4c70c",
        "id": "v2UW8fXRZhMlKgKAGRHf",
        "tagName": "VIP"
      }
    ],
    "evtLastUpdate": {
      "_seconds": 1672686830,
      "_nanoseconds": 736000000
    },
    "evtState": "inactive",
    "id": "M6KuuxzJFhRUHI2syqWc"
  }
];

let eventsStatistics: Statistic[] = [
  {
    "statsTotalQt": 3105,
    "statsTotalToSell": 0,
    "statsTotalQtSellByRrpp": 263,
    "statsTotalAmountSellByRrpp": 71800,
    "statsTotalQtSellByProductor": 154,
    "statsTotalAmountSellByProductor": 45250,
    "statsTotalQtSellByApp": 4,
    "statsTotalAmountSellByApp": 880,
    "statsTotalQtSell": 421,
    "statsTotalAmountSell": 117930,
    "statsGiftQt": 74,
    "statsGiftAmount": 0,
    "statsLoyalyQt": 1,
    "statsTotalAmountProducer": 117850,
    "statsTotalQtReferencedByRrpp": 2,
    "statsTotalAmountReferencedByRrpp": 660,
    "statsByType": [
      {
        "tktColor": "#cff708",
        "tktProducerName": "VIP $150",
        "tktTotalQt": 76,
        "tktTotalQtSellByRrpp": 85,
        "tktTotalAmountSellByRrpp": 12750,
        "tktTotalQtSellByProductor": 25,
        "tktTotalAmountSellByProductor": 3750,
        "tktTotalQtSellByApp": 0,
        "tktTotalAmountSellByApp": 0,
        "tktTotalQtSell": 110,
        "tktTotalAmountSell": 16500,
        "tktGiftQt": 14,
        "tktGiftAmount": 0,
        "tktLoyalyQt": 0,
        "tktTotalAmountProducer": 16500
      },
      {
        "tktColor": "#000000",
        "tktProducerName": "Combo Champagne + Speed - $10",
        "tktTotalQt": 830,
        "tktTotalQtSellByRrpp": 87,
        "tktTotalAmountSellByRrpp": 43500,
        "tktTotalQtSellByProductor": 66,
        "tktTotalAmountSellByProductor": 33000,
        "tktTotalQtSellByApp": 0,
        "tktTotalAmountSellByApp": 0,
        "tktTotalQtSell": 153,
        "tktTotalAmountSell": 76500,
        "tktGiftQt": 15,
        "tktGiftAmount": 0,
        "tktLoyalyQt": 1,
        "tktTotalAmountProducer": 76500
      },
      {
        "tktColor": "#de1717",
        "tktProducerName": "Entrada General - $100",
        "tktTotalQt": 356,
        "tktTotalQtSellByRrpp": 56,
        "tktTotalAmountSellByRrpp": 5600,
        "tktTotalQtSellByProductor": 52,
        "tktTotalAmountSellByProductor": 5200,
        "tktTotalQtSellByApp": 2,
        "tktTotalAmountSellByApp": 220,
        "tktTotalQtSell": 110,
        "tktTotalAmountSell": 11020,
        "tktGiftQt": 34,
        "tktGiftAmount": 0,
        "tktLoyalyQt": 0,
        "tktTotalAmountProducer": 11000
      },
      {
        "tktColor": "#000000",
        "tktProducerName": "Voucher 300 puntos",
        "tktTotalQt": 943,
        "tktTotalQtSellByRrpp": 33,
        "tktTotalAmountSellByRrpp": 9900,
        "tktTotalQtSellByProductor": 11,
        "tktTotalAmountSellByProductor": 3300,
        "tktTotalQtSellByApp": 2,
        "tktTotalAmountSellByApp": 660,
        "tktTotalQtSell": 46,
        "tktTotalAmountSell": 13860,
        "tktGiftQt": 11,
        "tktGiftAmount": 0,
        "tktLoyalyQt": 0,
        "tktTotalAmountProducer": 13800
      },
      {
        "tktColor": "#000000",
        "tktProducerName": "Test Combo Lucas",
        "tktTotalQt": 899,
        "tktTotalQtSellByRrpp": 2,
        "tktTotalAmountSellByRrpp": 50,
        "tktTotalQtSellByProductor": 0,
        "tktTotalAmountSellByProductor": 0,
        "tktTotalQtSellByApp": 0,
        "tktTotalAmountSellByApp": 0,
        "tktTotalQtSell": 2,
        "tktTotalAmountSell": 50,
        "tktGiftQt": 0,
        "tktGiftAmount": 0,
        "tktLoyalyQt": 0,
        "tktTotalAmountProducer": 50
      }
    ]
  }
];

let statisticsDetail: StatisticsDetail[] = [
  {
    "id": "cOFlxEsjKBdkTxgSqvcz5hyeIpE3",
    "name": "RRPP",
    "lastname": "RRPP",
    "totalQt": 1,
    "totalAmount": 330,
    "detailByType": [
      {
        "tktProducerName": "Voucher 300 puntos",
        "totalQt": 1,
        "totalAmount": 330
      }
    ]
  },
  {
    "id": "ITxCl2WFpIevFitxM1q9TGkZD9c2",
    "name": "rrpp2",
    "lastname": "mailinator ",
    "totalQt": 1,
    "totalAmount": 330,
    "detailByType": [
      {
        "tktProducerName": "Voucher 300 puntos",
        "totalQt": 1,
        "totalAmount": 330
      }
    ]
  }
];

let statisticsByProducer: StatisticsDetail[] = [
  {
    "id": "",
    "name": "Super",
    "lastname": "Admin",
    "totalQt": 95,
    "totalAmount": 30300,
    "detailByType": [
      {
        "tktProducerName": "Combo Champagne + Speed - $10",
        "totalQt": 45,
        "totalAmount": 22500
      },
      {
        "tktProducerName": "Entrada General - $100",
        "totalQt": 24,
        "totalAmount": 2400
      },
      {
        "tktProducerName": "Voucher 300 puntos",
        "totalQt": 10,
        "totalAmount": 3000
      },
      {
        "tktProducerName": "VIP $150",
        "totalQt": 16,
        "totalAmount": 2400
      }
    ]
  },
  {
    "id": "CygGLGhiT3V0MdT4suPemPrmcR42",
    "name": "Super",
    "lastname": "Admin",
    "totalQt": 51,
    "totalAmount": 14150,
    "detailByType": [
      {
        "tktProducerName": "Combo Champagne + Speed - $10",
        "totalQt": 21,
        "totalAmount": 10500
      },
      {
        "tktProducerName": "Entrada General - $100",
        "totalQt": 20,
        "totalAmount": 2000
      },
      {
        "tktProducerName": "Voucher 300 puntos",
        "totalQt": 1,
        "totalAmount": 300
      },
      {
        "tktProducerName": "VIP $150",
        "totalQt": 9,
        "totalAmount": 1350
      }
    ]
  },
  {
    "id": "WaDC7IWKG8grLspY5m84aup6EU03",
    "name": "Ariel Alejandro",
    "lastname": "Castro Busico",
    "totalQt": 8,
    "totalAmount": 800,
    "detailByType": [
      {
        "tktProducerName": "Entrada General - $100",
        "totalQt": 8,
        "totalAmount": 800
      }
    ]
  }
];

let statisticsDirectRrpp: StatisticsDetail[] = [
  {
    "id": "cOFlxEsjKBdkTxgSqvcz5hyeIpE3",
    "name": "RRPP",
    "lastname": "RRPP",
    "totalQt": 262,
    "totalAmount": 71500,
    "detailByType": [
      {
        "tktProducerName": "Combo Champagne + Speed - $10",
        "totalQt": 87,
        "totalAmount": 43500
      },
      {
        "tktProducerName": "Test Combo Lucas",
        "totalQt": 2,
        "totalAmount": 50
      },
      {
        "tktProducerName": "Entrada General - $100",
        "totalQt": 56,
        "totalAmount": 5600
      },
      {
        "tktProducerName": "Voucher 300 puntos",
        "totalQt": 32,
        "totalAmount": 9600
      },
      {
        "tktProducerName": "VIP $150",
        "totalQt": 85,
        "totalAmount": 12750
      }
    ]
  },
  {
    "id": "ITxCl2WFpIevFitxM1q9TGkZD9c2",
    "name": "rrpp2",
    "lastname": "mailinator ",
    "totalQt": 1,
    "totalAmount": 300,
    "detailByType": [
      {
        "tktProducerName": "Voucher 300 puntos",
        "totalQt": 1,
        "totalAmount": 300
      }
    ]
  }
];

let exchangesLoyalty: StatisticsDetail[] = [
  {
    "id": "27550252",
    "name": "Ariel Alejandro Castro Busico",
    "lastname": "Castro Busico",
    "totalQt": 1,
    "detailByType": [
      {
        "tktProducerName": "Combo Champagne + Speed - $10",
        "totalQt": 1
      }
    ]
  }
];

let statisticsGift: StatisticsDetail[] = [
  {
    "id": "cOFlxEsjKBdkTxgSqvcz5hyeIpE3",
    "name": "RRPP",
    "lastname": "RRPP",
    "totalQt": 43,
    "detailByType": [
      {
        "tktProducerName": "Combo Champagne + Speed - $10",
        "totalQt": 6
      },
      {
        "tktProducerName": "Entrada General - $100",
        "totalQt": 23
      },
      {
        "tktProducerName": "Voucher 300 puntos",
        "totalQt": 11
      },
      {
        "tktProducerName": "VIP $150",
        "totalQt": 3
      }
    ]
  },
  {
    "id": "CygGLGhiT3V0MdT4suPemPrmcR42",
    "name": "Super",
    "lastname": "Admin",
    "totalQt": 28,
    "detailByType": [
      {
        "tktProducerName": "Combo Champagne + Speed - $10",
        "totalQt": 9
      },
      {
        "tktProducerName": "Entrada General - $100",
        "totalQt": 10
      },
      {
        "tktProducerName": "VIP $150",
        "totalQt": 9
      }
    ]
},
{
  "id": "ITxCl2WFpIevFitxM1q9TGkZD9c2",
  "name": "rrpp2",
  "lastname": "mailinator ",
  "totalQt": 1,
  "detailByType": [
    {
      "tktProducerName": "Entrada General - $100",
      "totalQt": 1
    }
  ]
},
{
  "id": "nniVWjoy2LSrpK87f8ZJz041mhr2",
  "name": "Productor",
  "lastname": "Productor",
  "totalQt": 2,
  "detailByType": [
    {
      "tktProducerName": "VIP $150",
      "totalQt": 2
    }
  ]
  }
];

axiosMockAdapter.onGet('/api/events').reply(200, { events });

axiosMockAdapter.onPost('/api/events/create').reply((newEvent)=>{
  const data = JSON.parse(newEvent.data);

  if(data){
    events.push(data)
  }
  return [200, { message: "Event created successfully", events }, { status: 200, statusText: 'OK' }]
});

axiosMockAdapter.onGet('/api/events/statistics/direct-rrpp').reply(200, { statisticsDirectRrpp });

axiosMockAdapter.onGet('/api/events/statistics/gift').reply(200, { statisticsGift });

axiosMockAdapter.onGet('/api/events/statistics/producer').reply(200, { statisticsByProducer });

axiosMockAdapter.onGet('/api/events/statistics/list').reply(200, { eventsStatistics });

axiosMockAdapter.onGet('/api/events/statistics/list/referenced-rrpp').reply(200, { statisticsDetail });

axiosMockAdapter.onGet('/api/events/statistics/loyalty').reply(200, { exchangesLoyalty });

axiosMockAdapter.onDelete('/api/events/delete').reply((config) => {

  const eventId = config.params.eventId;
  const index = events.findIndex((event) => event.id === eventId);

  if (index !== -1) {
    events.splice(index, 1);

    return [200, { events }, { status: 200, statusText: 'OK' }];
  } else {
    return [404, { error: 'Event not found' }, { status: 404, statusText: 'Not Found', data: { message: 'Event not found' } }];
  }
});

axiosMockAdapter.onPost('/api/events').reply((newEvent)=>{
  const data = JSON.parse(newEvent.data);

  if(data){
    events.push(data)
  }
  return [200, { message: 'Evento creado correctamente', events }, { status: 200, statusText: 'OK' }]
})

axiosMockAdapter.onPut(/\/api\/events\/\w+/).reply((config) => {
  const eventData = JSON.parse(config.data);
  const index = events.findIndex((events) => events.id === eventData.id);
  if (index === -1) {
      return [404, { error: 'Evento no encontrado' }, { status: 404, statusText: 'Not Found', data: { message: 'Evento no encontrado' } }]
  }
  events[index] = eventData;
  return [200, eventData];
});

axiosMockAdapter.onDelete('api/events/tags').reply((config) => {
  const {eventId, tagId} = config.params;
  const eventIndex = events.findIndex((event) => event.id === eventId);

  if (eventIndex !== -1) {
    const event = events[eventIndex];
    const tagIndex = event.evtTags.findIndex((tag) => tag.id === tagId);

    if (tagIndex !== -1) {
      event.evtTags.splice(tagIndex, 1);

      return [ 200, { message: 'Tag eliminado correctamente', event }, { status: 200, statusText: 'OK' } ];
    } else {
      return [ 404, { error: 'Tag no encontrado en el evento' }, { status: 404, statusText: 'Not Found', data: { message: 'Tag no encontrado en el evento' }}];
    }
  } else {
    return [ 404, { error: 'Evento no encontrado' }, { status: 404, statusText: 'Not Found', data: { message: 'Evento no encontrado' }}];
  }
});


axiosMockAdapter.onDelete('/api/events/tags/all').reply((config) => {
  const {tagId} = config.params;
  if (tagId) {
    events.map((event)=>{
      const tagIndex = event.evtTags.findIndex((tag) => tag.id === tagId);
      if (tagIndex !== -1) {
        event.evtTags.splice(tagIndex, 1);
      }
    })
    return [ 200, { message: 'Tags eliminados correctamente', events }, { status: 200, statusText: 'OK' } ];
  } else {
    return [ 404, { error: 'TagId vacío' }, { status: 404, statusText: 'Not Found', data: { message: 'TagId vacío' }}];
  }
});