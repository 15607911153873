import axios from 'axios';
import Config from '../config/index';
import {firebaseAppQP} from './firebaseQP';

type ApiConfig = {
  url: string;
  timeout: number;
};

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL,
  timeout: 20000
};


const axiosInst = axios.create({
  baseURL: DEFAULT_API_CONFIG.url,
  timeout: DEFAULT_API_CONFIG.timeout,
});

axiosInst.interceptors.request.use(async (config) => {
  const userQP = firebaseAppQP.auth().currentUser;
  if (userQP) {
    // TODO: POR EL MOMENTO OBLIGAMOS A REFRESCAR TOKEN
    // VALIDAR NEGOCIO SI LOGOUT O CONTINUO
    const token = await userQP.getIdToken(true);
    config.headers['AuthorizationQP'] = `Bearer ${token}`;
  }
  return config;
});

export default axiosInst;
