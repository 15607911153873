import type { User } from 'src/models/user';

export const applyFilters = (data: any[], query: string, properties: string[]): User[] => {
  if (!query) { return data; }
  const normalizedQuery = query.toLowerCase();
  return data.filter(v => {
    return properties.some(property => { 
      const value = v[property];
      return value !== null && value.toLowerCase().includes(normalizedQuery);
    });
  });
};

export const filterByRoles = (users: User[], selectedRoles): User[] => {
  return users.filter(user => {
    return selectedRoles.every(selectedRol => {
      return user.proRoles.some(userRol => userRol.id === selectedRol.id);
    });
  });
};

export const filterByTags = (users: User[], selectedTags): User[] => {
  return users.filter(user => {
    return selectedTags.every(selectedTag => {
      return user.proTags.some(userTag => userTag.id === selectedTag.id);
    });
  });
};

export const filterByGender = (users: User[], genero: string): User[] => {
  if (genero.toLowerCase() === 'all') {
    return users;
  }
  return users.filter(user => user.proGender?.toLowerCase() === genero.toLowerCase());
};

export const filterByBirthday = (users: User[], month: string, dayFrom: string, dayTo: string): User[] => {
  return users.filter(user => {
    const userDate = new Date(user.proBirthDate);
    const userDayNumber = userDate.getDate();
    const userMonthNumber = userDate.getMonth() + 1;

    return (
      userMonthNumber === parseInt(month, 10) &&
      userDayNumber >= parseInt(dayFrom, 10) &&
      userDayNumber <= parseInt(dayTo, 10)
    );
  });
};