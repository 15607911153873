import {
    Box,
    Card,
    Typography,
    Container,
    Button,
    styled
  } from '@mui/material';
  import { Helmet } from 'react-helmet-async';
  import { useTranslation } from 'react-i18next';
  import Logo from 'src/components/LogoSign';
  import { Link as RouterLink,  } from 'react-router-dom';
  const MainContent = styled(Box)(
    () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
  );
  
  
  function Status401() {
    const { t }: { t: any } = useTranslation();
    return (
      <>
        <Helmet>
          <title>Status - 401</title>
        </Helmet>
        <MainContent>
          <Container maxWidth="md">
            <Box textAlign="center">
             <img alt="404" height={180} src="/static/images/status/401_unauthorized.webp" /> 
             {/* <Logo/> */}
              <Typography
                variant="h2"
                sx={{
                  my: 2
                }}
              >
                {t("Insufficient permissions to enter the module")}
              </Typography>
            
            </Box>
          </Container>
        </MainContent>
      </>
    );
  }
  
  export default Status401;
  