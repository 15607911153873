import { Box } from '@mui/material';
// import LanguageSwitcher from '../Buttons/LanguageSwitcher';
import Logout from '../Buttons/Logout';

function SidebarFooter() {
  return (
    <Box sx={{ height: 60 }} display="flex" alignItems="center" justifyContent="center">
      {/* SE DESCOMENTA PROVISIONALMENTE HASTA QUE TENGAMOS MANEJO DE LENGUAJES */}
      {/* <LanguageSwitcher /> */}
      <Logout />
    </Box>
  );
}

export default SidebarFooter;
