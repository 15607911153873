import { Outlet } from 'react-router-dom';
import { useSelector } from 'src/store';
import Status401 from 'src/content/pages/Status/Status401';

const RoleBasedRoute = ({ rolesCustom, element }) => {
  const { roles } = useSelector((state) => state.auth);
  // console.log(roles?.some((role) => rolesCustom.includes(role.id)));
  if (roles?.some((role) => rolesCustom.includes(role.id))) {
    return element || <Outlet />;
  } else {
    return <Status401 />;
  }
};

export default RoleBasedRoute;
