

import axiosMockAdapter from 'src/utils/mock';
import type { NotificationApp } from 'src/models/notificationApp';
import randomId from 'src/utils/randomId';
const urlMatch = new RegExp(/^\/api\/notifications\//);

let notifications: NotificationApp[] = [
    {
        "tskImmediate": false,
        "tskParams": {
            "title": "Push Agosto",
            "body": "Push automatica Agosto"
        },
        "tskTask": "notify",
        "tskRunDate": {
            "_seconds": 1670272080,
            "_nanoseconds": 0
        },
        "tskTags": [
            {
                "isTagVisible": false,
                "tagColor": "#1fe022",
                "id": "btj2McNknlZ3Rr68aeaP",
                "tagName": "AGOSTO"
            }
        ],
        "tskUsersNotified": [
            {
                "proLastName": "Castro Busico",
                "proDNI": "27550252",
                "proName": "Ariel Alejandro",
                "id": "WaDC7IWKG8grLspY5m84aup6EU03",
                "proEmail": "ariel.castro@gmail.com"
            },
            {
                "proLastName": "Villarroel",
                "proDNI": "40683409",
                "proName": "Lautaro",
                "id": "WaDC7IWKG8grLspY5m84aup6EU04",
                "proEmail": "lautaro.villarroel@gmail.com"
            },
            {
                "proLastName": "Lastname Test",
                "proDNI": "42894003",
                "proName": "Name Test",
                "id": "WaDC7IWKG8grLspY5m84aup6EU05",
                "proEmail": "test.user@gmail.com"
            },
            {
                "proLastName": "dasdasd",
                "proDNI": "deleted-null",
                "proName": "adsada",
                "id": "s9JM1nPEAfN6zJkolOMi72Fzyd52",
                "proEmail": "deleted-testkeps@mailinator.com"
            },
            {
                "proLastName": "mailinator ",
                "proDNI": "24",
                "proName": "24",
                "id": "SdQfVSTZjsdZYMKQJ9yg0z5lE1F2",
                "proEmail": "24@mailinator.com"
            },
            {
                "proLastName": "puntos",
                "proDNI": "12365423",
                "proName": "test",
                "id": "UxCxvSEtPYhmHua2oKxU7gvBzWF2",
                "proEmail": "12365423@mailinator.com"
            },
            {
                "proLastName": "mailinator",
                "proDNI": "27550249",
                "proName": "lula",
                "id": "MRNu1jK3KkOzwAU2XzgQHK6L9u92",
                "proEmail": "lula@mailinator.com"
            },
            {
                "proLastName": "123123124",
                "proDNI": "27550250",
                "proName": "tincho ",
                "id": "h6mLxGhhklMtcAPhbOM6FeyqWel2",
                "proEmail": "tincho@mailinator.com"
            },
            {
                "proLastName": "123123124",
                "proDNI": "123123124",
                "proName": "GerTest",
                "id": "WR0GFJOatGaULFDADOKLrhzKAZL2",
                "proEmail": "testger124@mailinator.com",
            }
        ],
        "tskStatus": "complete",
        "id": "frbPjNuORM5s8MtBPolP"
    },
    {
        "tskImmediate": false,
        "tskParams": {
            "body": "Push Septiembre",
            "title": "Push automatica Septiembre"
        },
        "tskRunDate": {
            "_seconds": 1670271900,
            "_nanoseconds": 0
        },
        "tskTask": "notify",
        "tskTags": [
            {
                "isTagVisible": false,
                "tagColor": "#9f2828",
                "id": "cRUSu1PtyY8HUkQNOKfY",
                "tagName": "SEPTEIMBRE"
            }
        ],
        "tskStatus": "error",
        "id": "XZKsj0uYlvqSW9ZOV3qU"
    },
    {
        "tskImmediate": false,
        "tskParams": {
            "image": "https://firebasestorage.googleapis.com/v0/b/bo-quickpass-3249a.appspot.com/o/notifications-image.PNG?alt=media&token=c6da1025-5b2a-4638-be44-3b4f4a8395e3",
            "title": "TEST Notificaciones",
            "body": "TEST Notificaciones descripción"
        },
        "tskTask": "notify",
        "tskRunDate": {
            "_seconds": 1670271600,
            "_nanoseconds": 0
        },
        "tskTags": [],
        "tskStatus": "complete",
        "id": "aa1WkaVdoqTjN5IaHmIa"
    },
    {
        "tskImmediate": true,
        "tskParams": {
            "body": "Inmediata MARZO",
            "title": "Inmediata MARZO"
        },
        "tskTask": "notify",
        "tskRunDate": {
            "_seconds": 1670271483,
            "_nanoseconds": 141000000
        },
        "tskTags": [
            {
                "isTagVisible": false,
                "tagColor": "#9f2828",
                "id": "cRUSu1PtyY8HUkQNOKfY",
                "tagName": "SEPTEIMBRE"
            }
        ],
        "tskStatus": "error",
        "id": "FxUUE5or42BUonKjZvWB"
    },
    {
        "tskImmediate": true,
        "tskParams": {
            "title": "Agosto",
            "body": "Agosto"
        },
        "tskRunDate": {
            "_seconds": 1670271463,
            "_nanoseconds": 816000000
        },
        "tskTask": "notify",
        "tskTags": [
            {
                "isTagVisible": false,
                "tagColor": "#1fe022",
                "id": "btj2McNknlZ3Rr68aeaP",
                "tagName": "AGOSTO"
            }
        ],
        "tskUsersNotified": [
            {
                "proLastName": "Castro Busico",
                "proDNI": "27550252",
                "id": "WaDC7IWKG8grLspY5m84aup6EU03",
                "proName": "Ariel Alejandro",
                "proEmail": "ariel.castro@gmail.com"
            }
        ],
        "tskStatus": "complete",
        "id": "BLqQziz43QDczcWq95tg"
    },
    {
        "tskImmediate": true,
        "tskParams": {
            "title": "Nueva notificacion",
            "body": "Nueva notificacion inmediata"
        },
        "tskRunDate": {
            "_seconds": 1670271443,
            "_nanoseconds": 374000000
        },
        "tskTask": "notify",
        "tskTags": [],
        "tskStatus": "complete",
        "id": "eeiDoowQhuLR23f9s8vN"
    }
];

axiosMockAdapter.onGet('/api/notifications').reply(200, { notifications });

axiosMockAdapter.onDelete(urlMatch).reply((config) => {

    const ntfDelete = JSON.parse(config.data);
    const index = notifications.findIndex((notification) => notification.id === ntfDelete.id);

    if (index !== -1) {
        notifications.splice(index, 1);

        return [200, { message: 'Notifiación eliminada correctamente' }, { status: 200, statusText: 'OK' }];
    } else {
        return [404, { error: 'Notifiación no encontrada' }, { status: 404, statusText: 'Not Found', data: { message: 'Notifiación no encontrada' } }];
    }
});
axiosMockAdapter.onPost('/api/notifications').reply((config) => {
    const ntfData = JSON.parse(config.data);
    ntfData.id= randomId();
    notifications.unshift(ntfData);
    return [201, ntfData];
});

axiosMockAdapter.onPut(urlMatch).reply((config) => {
    const ntfData = JSON.parse(config.data);
    const index = notifications.findIndex((notificacion) => notificacion.id === ntfData.id);
    if (index === -1) {
        return [404, { error: 'Notifiación no encontrada' }, { status: 404, statusText: 'Not Found', data: { message: 'Notifiación no encontrada' } }]
    }
    notifications[index] = ntfData;
    return [200, ntfData];
});
