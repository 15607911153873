import { createSlice } from '@reduxjs/toolkit';
import { CategoryLabel } from 'src/models/category';
import { rolPermission } from 'src/models/rol';

interface RolPermissionState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  rolPermissions: rolPermission[];
  permissions: CategoryLabel[];
  activeRolPermission: rolPermission | {};
  rolPermissionPaginated: rolPermission[];
  page: number;
  limit: number;
  changeRolPermission: null;
}

const initialState: RolPermissionState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  rolPermissions: [],
  permissions: [],
  activeRolPermission: {},
  rolPermissionPaginated: [],
  page: 0,
  limit: 25,
  changeRolPermission: null
};

export const rolPermissionSlice = createSlice({
  name: 'rolPermission',
  initialState,
  reducers: {
    startLoadingRolPermissions: (state) => {
      state.isLoading = true;
    },
    setRolPermissions: (state, action) => {
      state.isLoading = false;
      state.rolPermissions = [...action.payload];
    },
    startLoadingPermissions: (state) => {
      state.isLoading = true;
    },
    setPermissions: (state, action) => {
      state.isLoading = false;
      state.permissions = [...action.payload];
    },
    setRolPermissionsPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.rolPermissionPaginated = action.payload.roles.data;
    },
    setChangeRolPermission: (state, action) => {
      state.changeRolPermission = action.payload;
    },
    stopLoadingPermissions: (state) => {
      state.isLoading = false;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    }
  }
});

export const {
  startLoadingRolPermissions,
  setRolPermissions,
  setRolPermissionsPaginated,
  setError,
  setChangeRolPermission,
  startLoadingPermissions,
  setPermissions,
  stopLoadingPermissions
} = rolPermissionSlice.actions;