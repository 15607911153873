import useAuth from 'src/hooks/useAuth';
import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip, styled } from '@mui/material';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useTranslation } from 'react-i18next';

const IconButtonWrapper = styled(IconButton)(({ theme }) => `
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
  `
);

function Logout() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const { t }: { t: any } = useTranslation();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      closeSidebar();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Tooltip arrow title={t('Sign out')}>
        <IconButtonWrapper style={{ color: '#f2f5f9' }} onClick={handleLogout}>
          <LockOpenTwoToneIcon />
        </IconButtonWrapper>
      </Tooltip>
    </>
  );
}

export default Logout;
