import { createSlice } from '@reduxjs/toolkit';
import { New } from '../../models/new';

interface NewsState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  news: New[];
  activeNews: New | {};
  newsPaginated: New[];
  page: number;
  limit: number;
  changeNews: null;
  lastVisible: null;
  totalNews: number
}

const initialState: NewsState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  news: [],
  activeNews: {},
  newsPaginated: [],
  page: 0,
  limit: 25,
  changeNews: null,
  lastVisible: null,
  totalNews: 0
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    startLoadingNews: (state) => {
      state.isLoading = true;
    },
    stopLoadingNews: (state) => {
      state.isLoading = false;
    },
    setNews: (state, action) => {
      state.isLoading = false;
      state.news = [...action.payload];
    },
    setNewsPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.newsPaginated = action.payload.news;
      state.lastVisible = action.payload.lastVisible;
      state.totalNews = action.payload.totalNews;
    },
    setChangeNews: (state, action) => {
      state.changeNews = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    }
  }
});

export const {
  startLoadingNews,
  stopLoadingNews,
  setNews,
  setNewsPaginated,
  setError,
  setChangeNews
} = newsSlice.actions;