
import { createSlice } from '@reduxjs/toolkit';
import { getCurrentDate, getDateOneMonthAgo } from 'src/utils/datesRange';

interface StadisticState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  dayFrom: string;  
  dayTo: string;   
}

const initialState: StadisticState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  dayFrom: getDateOneMonthAgo().toISOString(),  
  dayTo: getCurrentDate().toISOString(),        
};

export const stadisticSlice = createSlice({
  name: 'stadistic',
  initialState,
  reducers: {
    setDates: (state, action) => {
      state.dayFrom = action.payload.dayFrom
      state.dayTo = action.payload.dayTo
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    }
  }
});

export const { setDates, setError } = stadisticSlice.actions;
export default stadisticSlice.reducer;
