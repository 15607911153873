import { createSlice } from '@reduxjs/toolkit';
import { Redimidor } from 'src/models/redimidor';

interface RolState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  roles: Redimidor[];
  activeRol: Redimidor | {};
  rolPaginated: Redimidor[];
  page: number;
  limit: number;
  changeRol: null;
}

const initialState: RolState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  roles: [],
  activeRol: {},
  rolPaginated: [],
  page: 0,
  limit: 25,
  changeRol: null
};

export const rolSlice = createSlice({
  name: 'rol',
  initialState,
  reducers: {
    startLoadingRoles: (state) => {
      state.isLoading = true;
    },
    setRoles: (state, action) => {
      state.isLoading = false;
      state.roles = [...action.payload];
    },
    setRolesPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.rolPaginated = action.payload.roles.data;
    },
    setChangeRol: (state, action) => {
      state.changeRol = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    stopLoadingRoles: (state) => {
      state.isLoading = false;
    }
  }
});

export const {
  startLoadingRoles,
  setRoles,
  setRolesPaginated,
  setError,
  setChangeRol,
  stopLoadingRoles
} = rolSlice.actions;