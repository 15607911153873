import { useSnackbar } from 'notistack';

let useSnackbarRef;

export const SnackbarUtils = {
  success(msg, duration = 4000) {  
    this.toast(msg, 'success', duration);
  },
  error(msg, duration = 4000) {
    this.toast(msg, 'error', duration);
  },
  warning(msg, duration = 4000) {
    this.toast(msg, 'warning', duration);
  },
  info(msg, duration = 4000) {
    this.toast(msg, 'info', duration);
  },
  toast(msg, variant = 'default', duration = 4000) {
    useSnackbarRef?.enqueueSnackbar(msg, { variant, autoHideDuration: duration });
  },
};

const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar(); 
  return null;
};

export default SnackbarUtilsConfigurator;
