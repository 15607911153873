import axiosMockAdapter from 'src/utils/mock';
import { New } from 'src/models/new';
import randomId from 'src/utils/randomId';

const urlMatch = new RegExp(/^\/api\/news\//);

let news: New[] = [
    {
        "nvtNotify": false,
        "nvtDate": {
            "_seconds": 1709931600,
            "_nanoseconds": 0
        },
        "nvtDiffusion": "public",
        "nvtTitle": "Apertura de nueva pista en Keops",
        "nvtDescription": "Descripcion apertura de nueva pista en Keops",
        "id": "2usaecvRd0obqwasjwsy"
    },
    {
        "nvtNotify": true,
        "nvtDate": {
            "_seconds": 1709409600,
            "_nanoseconds": 0
        },
        "nvtDiffusion": "private",
        "nvtNotificationId": "5L6forC3BdIBYjzlSQ8r",
        "nvtTitle": "Segunda novedad TEST",
        "nvtDescription": "Segunda novedad TEST descripción",
        "id": "9wK8pXLQvey3TEDfTB6e"
    },
    {
        "nvtNameImg": "Captura de pantalla 2024-02-28 105353.png",
        "nvtNotify": true,
        "nvtDate": {
            "_seconds": 1709316000,
            "_nanoseconds": 0
        },
        "nvtDiffusion": "public",
        "nvtNotificationId": "DdM2x52yfjo0EavbD06O",
        "nvtImg": "https://firebasestorage.googleapis.com/v0/b/keops-dev.appspot.com/o/news%2F1709231350588_Captura%20de%20pantalla%202024-02-28%20105353.png?alt=media&token=580f4c7b-26b7-4efb-94e6-6f306311d6b9",
        "nvtTitle": "Primera novedad TEST",
        "nvtDescription": "Primera novedad TEST descripción",
        "nvtPathImg": "news/1709231350588_Captura de pantalla 2024-02-28 105353.png",
        "id": "oCntL0vxaxx3t70SkIeH"
    }
];

axiosMockAdapter.onGet('/api/news').reply(200, { news });

axiosMockAdapter.onPost('/api/news').reply((config) => {
    const newsData = JSON.parse(config.data);
    newsData.id = randomId();
    news.push(newsData);
    return [201, newsData];
});

axiosMockAdapter.onPut(urlMatch).reply((config) => {
    const newsData = JSON.parse(config.data);
    const index = news.findIndex((news) => news.id === newsData.id);
    if (index === -1) {
        return [404, { error: 'Novedad no encontrada' }, { status: 404, statusText: 'Not Found', data: { message: 'Novedad no encontrada' } }]
    }
    news[index] = newsData;
    return [200, newsData];
});

axiosMockAdapter.onDelete(urlMatch).reply((config) => {

    const nwsDelete = JSON.parse(config.data);
    const index = news.findIndex((news) => news.id === nwsDelete.id);

    if (index !== -1) {
        news.splice(index, 1);

        return [200, { message: 'Novedad eliminada correctamente' }, { status: 200, statusText: 'OK' }];
    } else {
        return [404, { error: 'Novedad no encontrada' }, { status: 404, statusText: 'Not Found', data: { message: 'Novedad no encontrada' } }];
    }
});