import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Place } from 'src/models/place';

interface PlaceState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  places: Place[];
  activePlace: Place | {} | any;
  placePaginated: Place[];
  page: number;
  limit: number;
  changePlace: null | Place | {};
}

const initialState: PlaceState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  places: [],
  activePlace: null,
  placePaginated: [],
  page: 0,
  limit: 25,
  changePlace: null || {}
};

export const placeSlice = createSlice({
  name: 'place',
  initialState,
  reducers: {
    startLoadingPlaces: (state) => {
      state.isLoading = true;
    },
    setPlaces: (state, action) => {
      state.isLoading = false;
      state.places = [...action.payload];
    },
    setPlacesPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.placePaginated = action.payload.roles.data;
    },
    setChangePlace: (state, action) => {
      state.isLoading = false;
      state.isLoading = false;
      state.changePlace = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    stopLoadingPlaces: (state) => {
      state.isLoading = false;
    },
    setSelectedPlace: (state, action: PayloadAction<Place>) => {
      state.activePlace = action.payload;
    },
 
      setActivePlace: (state, action: PayloadAction<Place>) => {
        state.isLoading = false;
        state.activePlace = action.payload;
        state.places = [...state.places, action.payload];
      }
  }
});

export const {
  startLoadingPlaces,
  setPlaces,
  setPlacesPaginated,
  setError,
  setChangePlace,
  stopLoadingPlaces,
  setSelectedPlace,
  setActivePlace
} = placeSlice.actions;
