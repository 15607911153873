import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { es } from 'date-fns/locale';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import SnackbarUtilsConfigurator from './utils/sncakbarUtilsConfigurator';
import internationalization from 'src/i18n/i18n';

// TODO TEMPORALMENTE SE SETTEA ESPAÑOL POR DEFECTO VALIDAR CON QA DE PRUEBAS INTEGRALES
// MULTILENGUAJE.
// FILES INVOLUCRADOS: LOGIN (descomentar "LanguageSwitcher")
// SidebarFooter/index.tsx (descomentar "LanguageSwitcher")
internationalization.changeLanguage('es');

function App() {
  const content = useRoutes(router);
  const auth = useAuth();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <SnackbarProvider maxSnack={6} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <SnackbarUtilsConfigurator /> 
          <CssBaseline />
          {auth.isInitialized ? content : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;