import axiosMockAdapter from "src/utils/mock";
import { CategoryLabel } from "src/models/category";

const categories: CategoryLabel[] = [
    {
      "id": "Canje",
      "label": "Control Canje",
      "selected": true
    },
    {
      "id": "Combo",
      "label": "Control Combo",
      "selected": true
    },
    {
      "id": "Entrada",
      "label": "Control Entrada",
      "selected": true
    },
    {
      "id": "Ticket",
      "label": "Control Ticket",
      "selected": true
    },
    {
      "id": "Traslados",
      "label": "Control Traslados",
      "selected": true
    },
    {
      "id": "Voucher",
      "label": "Control Voucher",
      "selected": true
    }
  ];

  axiosMockAdapter.onGet('/api/categories').reply(200, { categories });

  axiosMockAdapter.onDelete('/api/categories/delete').reply((config) => {
  
      console.log('config.data: ', config.data);
      const categoryDelete = JSON.parse(config.data);
      const index = categories.findIndex((category) => category.id === categoryDelete.id);
  
      if (index !== -1) {
          categories.splice(index, 1);
  
          return [200, { message: 'Categoría eliminada correctamente' }, { status: 200, statusText: 'OK' }];
      } else {
          return [404, { error: 'Categoría no encontrada' }, { status: 404, statusText: 'Not Found', data: { message: 'Rol no encontrado' } }];
      }
  });
