import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import LogoIcon from 'src/common/assets/images/logoBlack.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function LogoHeader() {
  return (
    <LogoWrapper to="/dashboard/management/events/list">
      <Box
        component="img"
        src={LogoIcon}
        alt="Logo"
        sx={{
          width: "180px",
          height: 'auto',
          display: 'block',
          margin: '0 auto'
        }}
      />
    </LogoWrapper>
  );
}

export default LogoHeader;
