import type { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import BadgeIcon from '@mui/icons-material/Badge';
import LiveTvTwoToneIcon from '@mui/icons-material/LiveTvTwoTone';
import TheatersTwoToneIcon from '@mui/icons-material/TheatersTwoTone';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import CategoryIcon from '@mui/icons-material/Category';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { rolesApp } from 'src/utils/rolesConstants';

export interface MenuItem {
  link?: string;
  icon?: OverridableComponent<SvgIconTypeMap> & { muiName: string };
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
  roles?: string[];
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Management',
    items: [
      {
        name: 'Events',
        icon: LiveTvTwoToneIcon,
        link: '/dashboard/management/events',
        roles: [rolesApp.SUPERADMIN, rolesApp.PRODUCTOR, rolesApp.POS] 
      },
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: '/dashboard/management/users',
        roles: [rolesApp.SUPERADMIN, rolesApp.ADMIN]
      },
      {
        name: 'Roles',
        icon: BadgeIcon,
        link: '/dashboard/management/roles',
        roles: [rolesApp.SUPERADMIN, rolesApp.ADMIN]
      },
      {
        name: 'Notifications',
        icon: WallpaperIcon,
        link: '/dashboard/management/notifications',
        roles: [rolesApp.SUPERADMIN, rolesApp.PRODUCTOR] 
      },
      {
        name: 'News',
        icon: TheatersTwoToneIcon,
        link: '/dashboard/management/news',
        roles: [rolesApp.SUPERADMIN] 
      },
      {
        name: 'Statistics',
        icon: CategoryIcon,
        link: '/dashboard/management/statistics',
        roles: [rolesApp.SUPERADMIN, rolesApp.PRODUCTOR] 
      },
      {
        name: 'Tags',
        icon: AccountBoxIcon,
        link: '/dashboard/management/tags',
        roles: [rolesApp.SUPERADMIN, rolesApp.ADMIN]
      },
      {
        name: 'Sell',
        icon: StorefrontIcon,
        link: '/dashboard/management/sell/choose',
        roles: [rolesApp.SUPERADMIN, rolesApp.POS] 
      },
    ],
  },
];

export default menuItems;
