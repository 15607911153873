import { createSlice } from '@reduxjs/toolkit';
import { rolPermission } from 'src/models/rol';

import { User} from 'src/models/user';

interface AuthState {
  isLoading: boolean;
  profile: User;
  isErrorMessage: string;
  statusCode: number;
  error: null,
  roles: rolPermission[]
  isAuth:boolean
}


const initialState: AuthState = {
    isLoading: false,
    statusCode: 201,
    isErrorMessage: '',
    profile: null,
    roles: null,
    error: null,
    isAuth: false
  };
  
  export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      startLoading: (state) => {
        state.isLoading = true;
      },
      setUserAuth: (state, action) => {
        state.profile = action.payload.user;
        state.roles = action.payload.roles;
        state.isAuth = true;
      },
      setError: (state, action) => {
        const message = action.payload.message;
        state.statusCode = action.payload.statusCode;
        state.isErrorMessage = message;
      },
      stopLoading: (state) => {
        state.isLoading = false;
      },
    }
  });
  
  export const {
    startLoading,
    stopLoading,
    setUserAuth,
    setError,
  } = authSlice.actions;