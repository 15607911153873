import axiosMockAdapter from "src/utils/mock";
import { rolPermission } from "src/models/rol";

const rolPermissions: rolPermission[] = [
    {
      "id": "Admin"
    },
    {
      "id": "Default"
    },
    {
      "id": "Productor"
    },
    {
      "id": "Redimidor"
    },
    {
      "id": "Rrpp"
    },
    {
      "id": "SuperAdmin"
    }
  ];

axiosMockAdapter.onGet('/api/rolPermissions').reply(200, { rolPermissions });

axiosMockAdapter.onDelete('/api/rolPermissions/delete').reply((config) => {

  console.log('config.data: ', config.data);
  const roleDelete = JSON.parse(config.data);
  const index = rolPermissions.findIndex((role) => role.id === roleDelete.id);

  if (index !== -1) {
    rolPermissions.splice(index, 1);

    return [200, { message: 'Rol eliminado correctamente' }, { status: 200, statusText: 'OK' }];
  } else {
    return [404, { error: 'Rol no encontrado' }, { status: 404, statusText: 'Not Found', data: { message: 'Rol no encontrado' } }];
  }
});