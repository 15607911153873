import axiosMockAdapter from "src/utils/mock";
import { Place } from "src/models/place";

let places: Place[] = [
	{
		"plcAddress": "Av. Amadeo Sabattini 4591",
		"plcCoordinates": {
			"_latitude": null,
			"_longitude": null
		},
		"plcName": "Arco de Córdoba",
		"id": "cvEHCGh7v83maxqi8fbx"
	},
	{
		"plcAddress": "Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina",
		"plcCoordinates": {
			"_latitude": null,
			"_longitude": null
		},
		"plcName": "Keops",
		"id": "lMT7mH5IqQ6ZtRQtWWkF"
	},
	{
		"plcAddress": "Av. Colón, X5000EPZ Córdoba, Argentina",
		"plcCoordinates": {
			"_longitude": -64.1957887,
			"_latitude": -31.40931789999999
		},
		"id": "8q8S6IhgKddaEOXNyTDL",
		"plcName": "Probando nueva ubicación"
	}
];

axiosMockAdapter.onGet('/api/places').reply(200, { places });

axiosMockAdapter.onDelete('/api/places/delete').reply((config) => {

	console.log('config.data: ', config.data);
	const placeDelete = JSON.parse(config.data);
	const index = places.findIndex((event) => event.id === placeDelete.id);

	if (index !== -1) {
		places.splice(index, 1);

		return [200, { message: 'Ubicación eliminada correctamente' }, { status: 200, statusText: 'OK' }];
	} else {
		return [404, { error: 'Ubicación no encontrada' }, { status: 404, statusText: 'Not Found', data: { message: 'Rol no encontrado' } }];
	}
});
