import axiosMockAdapter from 'src/utils/mock';
import type { StatisticByClient, StatisticByEvent, StatisticByRRPP } from 'src/models/statistic';

let statisticByClient: StatisticByClient[] = [
    {
        "tktBuyerDU": "32346611",
        "tktBuyerLastName": "viudez ",
        "tktBuyerName": "ángel emmanuel  viudez ",
        "proTags": [
            {
                "id": "AJIljdkJdPqPNG0PioH5",
                "tagName": "MALE",
                "tagColor": "#1fe022",
                "isTagVisible": false
            },
            {
                "id": "YR4kEcYPvTfJmFNOerOn",
                "tagName": "ENERO",
                "tagColor": "#1fe022",
                "isTagVisible": false
            }
        ],
        "Qt": 94,
        "Total": 0,
        "TotalProducer": 0
    },
    {
        "tktBuyerDU": "45144578",
        "tktBuyerLastName": "Mazzarisi",
        "tktBuyerName": "Nicolas Ezequiel Mazzarisi",
        "proTags": [
            {
                "id": "AJIljdkJdPqPNG0PioH5",
                "tagName": "MALE",
                "tagColor": "#1fe022",
                "isTagVisible": false
            },
            {
                "id": "xvqZuvmdFchEvejaB0CT",
                "tagName": "SEPTIEMBRE",
                "tagColor": "#1fe022",
                "isTagVisible": false
            }
        ],
        "Qt": 56,
        "Total": 0,
        "TotalProducer": 0
    },
    {
        "tktBuyerDU": "16326697",
        "tktBuyerLastName": "Lopez",
        "tktBuyerName": "Hugo Alcides Lopez",
        "proTags": [
            {
                "id": "AJIljdkJdPqPNG0PioH5",
                "tagName": "MALE",
                "tagColor": "#1fe022",
                "isTagVisible": false
            },
            {
                "id": "USesvJRLLt3qpKkaV2He",
                "tagName": "ABRIL",
                "tagColor": "#1fe022",
                "isTagVisible": false
            }
        ],
        "Qt": 48,
        "Total": 258500,
        "TotalProducer": 235000
    },
    {
        "tktBuyerDU": "43189237",
        "tktBuyerLastName": "P",
        "tktBuyerName": "F P",
        "proTags": [
            {
                "id": "AJIljdkJdPqPNG0PioH5",
                "tagName": "MALE",
                "tagColor": "#1fe022",
                "isTagVisible": false
            },
            {
                "id": "GNX3G7atZMxBcLvIEAbC",
                "tagName": "FEBRERO",
                "tagColor": "#1fe022",
                "isTagVisible": false
            }
        ],
        "Qt": 41,
        "Total": 185000,
        "TotalProducer": 185000
    },
    {
        "tktBuyerDU": "46042631",
        "tktBuyerLastName": "Palou",
        "tktBuyerName": "Augusto Palou",
        "proTags": [
            {
                "id": "AJIljdkJdPqPNG0PioH5",
                "tagName": "MALE",
                "tagColor": "#1fe022",
                "isTagVisible": false
            }
        ],
        "Qt": 38,
        "Total": 195000,
        "TotalProducer": 195000
    },
    {
        "tktBuyerDU": "18086260",
        "tktBuyerLastName": "Tebes",
        "tktBuyerName": "Hector Mario Tebes",
        "proTags": [
            {
                "id": "AJIljdkJdPqPNG0PioH5",
                "tagName": "MALE",
                "tagColor": "#1fe022",
                "isTagVisible": false
            },
            {
                "id": "xvqZuvmdFchEvejaB0CT",
                "tagName": "SEPTIEMBRE",
                "tagColor": "#1fe022",
                "isTagVisible": false
            }
        ],
        "Qt": 37,
        "Total": 269500,
        "TotalProducer": 245000
    },
    {
        "tktBuyerDU": "42445585",
        "tktBuyerLastName": "Morales",
        "tktBuyerName": "Valentin Morales",
        "proTags": [
            {
                "id": "AJIljdkJdPqPNG0PioH5",
                "tagName": "MALE",
                "tagColor": "#1fe022",
                "isTagVisible": false
            },
            {
                "id": "BRFKkqIVOTrERA0OLcnH",
                "tagName": "MARZO",
                "tagColor": "#1fe022",
                "isTagVisible": false
            }
        ],
        "Qt": 37,
        "Total": 210000,
        "TotalProducer": 210000
    },
    {
        "tktBuyerDU": "14095858",
        "tktBuyerLastName": "Villalba",
        "tktBuyerName": "Sandra Silvia Villalba",
        "proTags": [
            {
                "id": "7R6ld8wxynDgKUrtYhQa",
                "tagName": "FEMALE",
                "tagColor": "#FFC0CB",
                "isTagVisible": false
            }
        ],
        "Qt": 36,
        "Total": 231000,
        "TotalProducer": 210000
    },
    {
        "tktBuyerDU": "44525711",
        "tktBuyerLastName": "Carrizo",
        "tktBuyerName": "Loana Carrizo",
        "proTags": [
            {
                "id": "7R6ld8wxynDgKUrtYhQa",
                "tagName": "FEMALE",
                "tagColor": "#FFC0CB",
                "isTagVisible": false
            },
            {
                "id": "bzedKyeuvMbkfFmSTqVd",
                "tagName": "NOVIEMBRE",
                "tagColor": "#1fe022",
                "isTagVisible": false
            }
        ],
        "Qt": 33,
        "Total": 65000,
        "TotalProducer": 65000
    },
    {
        "tktBuyerDU": "33881956",
        "tktBuyerLastName": "Torress",
        "tktBuyerName": "Candela Torress",
        "proTags": null,
        "Qt": 33,
        "Total": 117600,
        "TotalProducer": 108000
    }

];

let statisticByEvent = [
    {
        "evtPlace": "KEOPS",
        "evtSellVerify": false,
        "evtProducer": "1",
        "evtCreateUser": "superadm@mailinator.com",
        "evtPlaceAddress": "Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina",
        "evtInitDate": {
          "_seconds": 1670374020,
          "_nanoseconds": 0
        },
        "evtTitle": "BENEFICIOS - CANJES",
        "evtPlaceId": "4lU6J0TBg7Db5rB6h8ES",
        "evtConditions": "BENEFICIOS DE KEOPS APP - SOLO DISPONIBLE PARA EL TITULAR DEL TICKET - NO VALIDO PARA CANJEAR POR DINERO EN EFECTIVO - PARA USO EXCLUSIVO A TRAVES DE LA APLICACIÓN",
        "evtCreated": {
          "_seconds": 1670460501,
          "_nanoseconds": 837000000
        },
        "evtSellRrppEnable": false,
        "evtName": "BENEFICIOS - CANJES",
        "evtCoordinates": {
          "_latitude": -31.4024536,
          "_longitude": -64.46538029999999
        },
        "evtState": "active",
        "evtDescription": "BENEFICIOS - CANJES KEOPS APP",
        "evtTags": [
          {
            "isTagVisible": true,
            "tagColor": "#bc00f0",
            "id": "Fn1dkjSNk33fUkVQTDgg",
            "tagName": "QuickPass"
          }
        ],
        "evtImgPath": "events/1693497869203_keopsbenefits.jpg",
        "evtImg": "https://firebasestorage.googleapis.com/v0/b/keops-prod.appspot.com/o/events%2F1693497869203_keopsbenefits.jpg?alt=media&token=f95d2398-1da1-4943-b9f5-09ab64c246c1",
        "evtImgName": "keopsbenefits.jpg",
        "evtDateTime": {
          "_seconds": 1735700340,
          "_nanoseconds": 0
        },
        "evtEndDate": {
          "_seconds": 1735700340,
          "_nanoseconds": 0
        },
        "evtLastUpdateUser": "valentinreyna80@gmail.com",
        "evtLastUpdate": {
          "_seconds": 1709329368,
          "_nanoseconds": 351000000
        },
        "evtQt": 4347,
        "evtTotal": 0,
        "evtTotalProducer": 0,
        "tktTypeData": [
          {
            "tktVisible": false,
            "tktMaxQtBuy": 100,
            "tktSoldOut": false,
            "tktTags": [],
            "tktDisplayName": "VOUCHER $10.000",
            "tktSellRrppEnable": false,
            "tktCategory": "Canje",
            "tktPriceProducer": 10,
            "tktCreateUser": "superadm@mailinator.com",
            "tktIconFamily": "FontAwesome",
            "tktInitDate": {
              "_seconds": 1669863600,
              "_nanoseconds": 0
            },
            "tktName": "VOUCHER $10.000",
            "tktIcon": "dollar",
            "tktCreated": {
              "_seconds": 1672352395,
              "_nanoseconds": 790000000
            },
            "tktComboSubTickets": [],
            "tktProducerName": "VOUCHER $10.000",
            "tktPrice": 10,
            "tktColor": "#8b9068",
            "tktLoyaltiPoints": 0,
            "tktLastUpdateUser": "info.quickpass@gmail.com",
            "tktQtOriginal": 10000,
            "tktEndDate": {
              "_seconds": 1709261940,
              "_nanoseconds": 0
            },
            "tktLastUpdate": {
              "_seconds": 1706638520,
              "_nanoseconds": 23000000
            },
            "tktOutdateDate": {
              "_seconds": 1709261940,
              "_nanoseconds": 0
            },
            "tktQt": 2203,
            "id": "lI1zDtfGY2xYPx6qkh1E",
            "Qt": 2983,
            "Total": 0,
            "TotalProducer": 0
          },
          {
            "tktMaxQtBuy": 100,
            "tktVisible": false,
            "tktSoldOut": false,
            "tktTags": [],
            "tktDisplayName": "VOUCHER $5000",
            "tktSellRrppEnable": false,
            "tktCategory": "Canje",
            "tktPriceProducer": 10,
            "tktCreateUser": "superadm@mailinator.com",
            "tktIconFamily": "FontAwesome",
            "tktInitDate": {
              "_seconds": 1669863600,
              "_nanoseconds": 0
            },
            "tktName": "VOUCHER $5000",
            "tktIcon": "dollar",
            "tktCreated": {
              "_seconds": 1672351916,
              "_nanoseconds": 107000000
            },
            "tktComboSubTickets": [],
            "tktPrice": 10,
            "tktProducerName": "VOUCHER $5000",
            "tktColor": "#8b9068",
            "tktLoyaltiPoints": 0,
            "tktQtOriginal": 10000,
            "tktLastUpdateUser": "valentinreyna80@gmail.com",
            "tktEndDate": {
              "_seconds": 1735527540,
              "_nanoseconds": 0
            },
            "tktLastUpdate": {
              "_seconds": 1707714689,
              "_nanoseconds": 967000000
            },
            "tktOutdateDate": {
              "_seconds": 1735527540,
              "_nanoseconds": 0
            },
            "tktQt": 8309,
            "id": "NjKGmrPdBzGsQWSiDMa2",
            "Qt": 341,
            "Total": 0,
            "TotalProducer": 0
          },
          {
            "tktVisible": false,
            "tktSoldOut": false,
            "tktTags": [],
            "tktDisplayName": "VOUCHER $1000",
            "tktSellRrppEnable": false,
            "tktCategory": "Canje",
            "tktCreateUser": "superadm@mailinator.com",
            "tktPriceProducer": 10,
            "tktIconFamily": "FontAwesome",
            "tktInitDate": {
              "_seconds": 1669863600,
              "_nanoseconds": 0
            },
            "tktIcon": "dollar",
            "tktName": "VOUCHER $1000",
            "tktCreated": {
              "_seconds": 1670891520,
              "_nanoseconds": 759000000
            },
            "tktComboSubTickets": [],
            "tktQtOriginal": 10000,
            "tktPrice": 10,
            "tktProducerName": "VOUCHER $1000",
            "tktColor": "#8b9068",
            "tktMaxQtBuy": 100,
            "tktLoyaltiPoints": 0,
            "tktLastUpdateUser": "valentinreyna80@gmail.com",
            "tktEndDate": {
              "_seconds": 1735635540,
              "_nanoseconds": 0
            },
            "tktLastUpdate": {
              "_seconds": 1707010047,
              "_nanoseconds": 978000000
            },
            "tktOutdateDate": {
              "_seconds": 1767236340,
              "_nanoseconds": 0
            },
            "tktQt": 8656,
            "id": "97HxKJsl7y5RGaGe2Urx",
            "Qt": 248,
            "Total": 0,
            "TotalProducer": 0
          },
          {
            "tktVisible": false,
            "tktMaxQtBuy": 100,
            "tktSoldOut": false,
            "tktTags": [],
            "tktDisplayName": "VOUCHER $6000",
            "tktSellRrppEnable": false,
            "tktCategory": "Canje",
            "tktPriceProducer": 10,
            "tktCreateUser": "superadm@mailinator.com",
            "tktIconFamily": "FontAwesome",
            "tktInitDate": {
              "_seconds": 1669863600,
              "_nanoseconds": 0
            },
            "tktName": "VOUCHER $6000",
            "tktIcon": "dollar",
            "tktCreated": {
              "_seconds": 1672352158,
              "_nanoseconds": 189000000
            },
            "tktComboSubTickets": [],
            "tktPrice": 10,
            "tktProducerName": "VOUCHER $6000",
            "tktColor": "#8b9068",
            "tktLoyaltiPoints": 0,
            "tktLastUpdateUser": "info.quickpass@gmail.com",
            "tktQtOriginal": 10000,
            "tktEndDate": {
              "_seconds": 1709261940,
              "_nanoseconds": 0
            },
            "tktLastUpdate": {
              "_seconds": 1706638552,
              "_nanoseconds": 191000000
            },
            "tktOutdateDate": {
              "_seconds": 1709261940,
              "_nanoseconds": 0
            },
            "tktQt": 9252,
            "id": "zQ6k0oIudtgIz6kSMC5Z",
            "Qt": 180,
            "Total": 0,
            "TotalProducer": 0
          },
          {
            "tktVisible": false,
            "tktMaxQtBuy": 100,
            "tktSoldOut": false,
            "tktTags": [],
            "tktDisplayName": "VOUCHER $2000",
            "tktSellRrppEnable": false,
            "tktCategory": "Canje",
            "tktPriceProducer": 10,
            "tktCreateUser": "superadm@mailinator.com",
            "tktIconFamily": "FontAwesome",
            "tktInitDate": {
              "_seconds": 1669863600,
              "_nanoseconds": 0
            },
            "tktName": "VOUCHER $2000",
            "tktIcon": "dollar",
            "tktCreated": {
              "_seconds": 1672351221,
              "_nanoseconds": 152000000
            },
            "tktComboSubTickets": [],
            "tktProducerName": "VOUCHER $2000",
            "tktPrice": 10,
            "tktColor": "#8b9068",
            "tktLoyaltiPoints": 0,
            "tktQtOriginal": 10000,
            "tktLastUpdateUser": "valentinreyna80@gmail.com",
            "tktEndDate": {
              "_seconds": 1735700340,
              "_nanoseconds": 0
            },
            "tktLastUpdate": {
              "_seconds": 1707010061,
              "_nanoseconds": 931000000
            },
            "tktOutdateDate": {
              "_seconds": 1735700340,
              "_nanoseconds": 0
            },
            "tktQt": 8967,
            "id": "CA23g3jbU3Qk1tAhnz7O",
            "Qt": 170,
            "Total": 0,
            "TotalProducer": 0
          }
        ],
        "evtId": "RcuV6eJsmGWL5WHwfuud"
      }    
] as StatisticByEvent[] ;

let statisticByRRPP: StatisticByRRPP[] = [
    {
      "tktRrpp": "scBY1pUodTeLoZZaUEwcCbld4Uh2",
      "Qt": 922,
      "Total": 9608500,
      "TotalProducer": 9608500,
      "rrpp": {
        "name": "josemir",
        "isActive": true,
        "lastname": "miguel"
      }
    },
    {
      "tktRrpp": "RaFoAohr2rc90vrZ1MRkpu6tCpu1",
      "Qt": 465,
      "Total": 5047500,
      "TotalProducer": 5047500,
      "rrpp": {
        "name": "Ezequiel",
        "isActive": true,
        "lastname": "Grego"
      }
    },
    {
      "tktRrpp": "qTTic7AKllUBlLeEcxQsIHnWAJT2",
      "Qt": 376,
      "Total": 4514500,
      "TotalProducer": 4514500,
      "rrpp": {
        "name": "Pilar ",
        "isActive": true,
        "lastname": "López "
      }
    },
    {
      "tktRrpp": "wYaKmPpDGTUv5N4yajnceIz5yUn1",
      "Qt": 331,
      "Total": 3691000,
      "TotalProducer": 3691000,
      "rrpp": {
        "name": "Juli",
        "isActive": true,
        "lastname": "Alvarez"
      }
    },
    {
      "tktRrpp": "k1HUTldZdNZee0BJLGZzVYNnrUC3",
      "Qt": 328,
      "Total": 3737000,
      "TotalProducer": 3737000,
      "rrpp": {
        "name": "Martina",
        "isActive": true,
        "lastname": "Bullaude"
      }
    },
    {
      "tktRrpp": "VckVm2aRpXZr0PVBlh8qCYgGoN92",
      "Qt": 327,
      "Total": 3790500,
      "TotalProducer": 3790500,
      "rrpp": {
        "name": "Emiliano",
        "isActive": true,
        "lastname": "Santillan"
      }
    },
    {
      "tktRrpp": "ygaJzwqpeLYVFgbXEHB7hwPKZvh1",
      "Qt": 302,
      "Total": 3612000,
      "TotalProducer": 3612000,
      "rrpp": {
        "name": "Nicolas",
        "lastname": "Serre",
        "isActive": true
      }
    }
];

axiosMockAdapter.onGet('/api/statistics/client/list').reply(200, { statisticByClient });

axiosMockAdapter.onGet('/api/statistics/event/list').reply(200, { statisticByEvent });

axiosMockAdapter.onGet('/api/statistics/rrpp/list').reply(200, { statisticByRRPP });