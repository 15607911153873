import { createSlice } from '@reduxjs/toolkit';
import { NotificationApp } from 'src/models/notificationApp';

interface NotificationAppState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  notifications: NotificationApp[];
  activeNotification: NotificationApp | {};
  notificationPaginated: NotificationApp[];
  page: number;
  limit: number;
  changeNotification: null;
}

const initialState: NotificationAppState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  notifications: [],
  activeNotification: {},
  notificationPaginated: [],
  page: 0,
  limit: 25,
  changeNotification: null
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startLoadingNotifications: (state) => {
      state.isLoading = true;
    },
    setNotifications: (state, action) => {
      state.isLoading = false;
      state.notifications = [...action.payload];
    },
    setNotificationsPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.notificationPaginated = action.payload.notifications.data;
    },
    setChangeNotification: (state, action) => {
      state.changeNotification = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    }
  }
});

export const {
  startLoadingNotifications,
  setNotifications,
  setNotificationsPaginated,
  setError,
  setChangeNotification
} = notificationSlice.actions;