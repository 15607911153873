import { createSlice } from '@reduxjs/toolkit';
import { CategoryLabel } from 'src/models/category';

interface CategoryState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  categories: CategoryLabel[];
  activeCategory: CategoryLabel | {};
  categoryPaginated: CategoryLabel[];
  page: number;
  limit: number;
  changeCategory: null;
}

const initialState: CategoryState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  categories: [],
  activeCategory: {},
  categoryPaginated: [],
  page: 0,
  limit: 25,
  changeCategory: null
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    startLoadingCategories: (state) => {
      state.isLoading = true;
    },
    setCategories: (state, action) => {
      state.isLoading = false;
      state.categories = [...action.payload];
    },
    setCategoriesPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.categoryPaginated = action.payload.roles.data;
    },
    setChangeCategory: (state, action) => {
      state.changeCategory = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    stopLoadingCategories: (state) => {
      state.isLoading = false;
    }
  }
});

export const {
  startLoadingCategories,
  setCategories,
  setCategoriesPaginated,
  setError,
  setChangeCategory,
  stopLoadingCategories
} = categorySlice.actions;