import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Box, Drawer, styled, Divider, useTheme, darken, Typography, Chip } from '@mui/material';
//import SidebarTopSection from './SidebarTopSection';
import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';
import Logo from 'src/components/LogoSign';
import { useSelector } from 'src/store';


const SidebarWrapper = styled(Box)(({ theme }) => `
    width: ${theme.sidebar.width};
    min-width: ${theme.sidebar.width};
    color: ${theme.colors.alpha.trueWhite[70]};
    position: relative;
    z-index: 7;
    height: 100%;
    padding-bottom: 61px;
  `
);

const UserInfoContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  fontWeight: 'bold',
}));

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const { roles, profile } = useSelector((state) => state.auth);
  const theme = useTheme();
  const rolesRedux = roles;

  const userName = `${profile?.proName || ''} ${profile?.proLastName || ''}`.trim();
  const userEmail = profile?.proEmail || '';
  const userRoles = Array.isArray(profile?.proRoles) && profile.proRoles.length > 0
    ? profile.proRoles
    : [{ id: 'Usuario sin roles' }];

  return (
    <>
      <Drawer
        sx={{ boxShadow: `${theme.sidebar.boxShadow}` }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper 
          sx={{ 
            background: theme.palette.mode === 'dark' ? 
              theme.colors.alpha.white[100] : 
              darken(theme.colors.alpha.black[100], 0.5) 
            }}
          >
          <Scrollbar>
            <Box mt={3}>
              <Box mx={2} sx={{ width: 52 }}>
                <Logo />
              </Box>
            </Box>
            <UserInfoContainer>
              <Typography variant="body1" sx={{ color: theme.palette.common.white, fontWeight: 'bold' }}>
                {userName !== '' ? userName : ''}
              </Typography>
              <Typography variant="body2" sx={{ color: theme.palette.common.white }}>
                {userEmail}
              </Typography>
              <Box display="flex" flexWrap="wrap" sx={{ marginTop: 2 }} gap={1}>
                {userRoles.map((role) => (
                  <StyledChip key={role.id} label={role.id} />
                ))}
              </Box>
            </UserInfoContainer>
            <Divider sx={{ my: theme.spacing(1), mx: theme.spacing(2), background: theme.colors.alpha.trueWhite[10] }}/>
             <SidebarMenu rolesRedux={rolesRedux} />
          </Scrollbar>
          <SidebarFooter />
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
