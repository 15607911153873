import { createSlice } from '@reduxjs/toolkit';
import { Event, EvtTicketProcessCreateByDU } from 'src/models/event';
import { Statistic, StatisticsDetail } from 'src/models/statistic';

interface EventState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  events: Event[];
  activeEvent: Event;
  eventPaginated: Event[];
  page: number;
  limit: number;
  changeEvent: null | Event;
  typeEditonEvent: string;
  isLoadingEvtTktProcess: boolean;
  changeEvtTktProcess: null | EvtTicketProcessCreateByDU;
  quantityTkt: any;
  selectedTickets: any;

  isLoadingEventsStatistics: boolean;
  isErrorMessageEventsSts: string;
  eventsStatistics: Statistic;
  eventStatisticsPaginated: Statistic[];
  changeEventStatistics: null | Event;

  isLoadingStatisticsDetail: boolean;
  isErrorMessageReferencedRrpp: string;
  statisticsDetail: StatisticsDetail[];
  statisticsDetailPaginated: StatisticsDetail[];
  changeStatisticsDetail: null | Event;

  isLoadingStatisticsByProducer: boolean;
  isErrorMessageStatisticsByProducer: string;
  statisticsByProducer: StatisticsDetail[];
  statisticsByProducerPaginated: StatisticsDetail[];
  changeStatisticsByProducer: null | Event;

  isLoadingDirectRrpp: boolean;
  isErrorMessageDirectRrpp: string;
  statisticsDirectRrpp: StatisticsDetail[];
  directRrppPaginated: StatisticsDetail[];
  changeDirectRrpp: null | Event;

  isLoadingLoyalty: boolean;
  isErrorMessageLoyalty: string;
  exchangesLoyalty: StatisticsDetail[];
  loyaltyPaginated: StatisticsDetail[];
  changeLoyalty: null | Event;

  isLoadingStsGift: boolean;
  isErrorMessageStsGift: string;
  statisticsGift: StatisticsDetail[];
  stsGiftPaginated: StatisticsDetail[];
  changeStsGift: null | Event;
};

const initialState: EventState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  events: [],
  activeEvent: null,
  eventPaginated: [],
  page: 0,
  limit: 25,
  changeEvent: null,
  typeEditonEvent: '',
  isLoadingEvtTktProcess: false,
  changeEvtTktProcess: null,
  quantityTkt: null,
  selectedTickets: null,

  isLoadingEventsStatistics: false,
  isErrorMessageEventsSts: '',
  eventsStatistics: null,
  eventStatisticsPaginated: [],
  changeEventStatistics: null,

  isLoadingStatisticsDetail: false,
  isErrorMessageReferencedRrpp: '',
  statisticsDetail: [],
  statisticsDetailPaginated: [],
  changeStatisticsDetail: null,

  isLoadingStatisticsByProducer: false,
  isErrorMessageStatisticsByProducer: '',
  statisticsByProducer: [],
  statisticsByProducerPaginated: [],
  changeStatisticsByProducer: null,

  isLoadingDirectRrpp: false,
  isErrorMessageDirectRrpp: '',
  statisticsDirectRrpp: [],
  directRrppPaginated: [],
  changeDirectRrpp: null,

  isLoadingLoyalty: false,
  isErrorMessageLoyalty: '',
  exchangesLoyalty: [],
  loyaltyPaginated: [],
  changeLoyalty: null,

  isLoadingStsGift: false,
  isErrorMessageStsGift: '',
  statisticsGift: [],
  stsGiftPaginated: [],
  changeStsGift: null,
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    startLoadingEvents: (state) => {
      state.isLoading = true;
    },
    setEvents: (state, action) => {
      state.isLoading = false;
      state.events = [...action.payload];
    },
    setEventsPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.eventPaginated = action.payload.events.data;
    },
    setChangeEvent: (state, action) => {
      state.isLoading = false;
      state.changeEvent = action.payload;
    },
    setError: (state, action) => {
      state.isLoading = false;
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    setActiveEvent: (state, action) => {
      state.isLoading = false;
      state.activeEvent = action.payload
    },
    stopLoadingEvent: (state)=>{
      state.isLoading = false;
    },
    setChangeEvtTktProcess: (state, action) => {
      state.isLoadingEvtTktProcess = false;
      state.changeEvtTktProcess = action.payload;
    },
    startLoadingEvtTktProcessByDU: (state) => {
      state.isLoadingEvtTktProcess = true;
    },
    stopLoadingEvtTktProcessByDU: (state) => {
      state.isLoadingEvtTktProcess = false;
    },
    setQuantityTkt:(state, action) => {
      state.quantityTkt = action.payload;
    },
    setSelectedTickets: (state, action) => {
      state.selectedTickets = action.payload;
    },
    //Statistics
    startLoadingEventsStatistics: (state) => {
      state.isLoadingEventsStatistics = true;
    },
    setEventsStatistics: (state, action) => {
      state.isLoadingEventsStatistics = false;
      state.eventsStatistics = action.payload.data
      ;
    },
    setEventsStatisticsPaginated: (state, action) => {
      state.isLoadingEventsStatistics = false;
      state.page = action.payload.page;
      state.eventStatisticsPaginated = action.payload.eventsStatistics.data;
    },
    setChangeEventStatistics: (state, action) => {
      state.changeEventStatistics = action.payload;
    },
    setErrorEventsStatistics: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessageEventsSts = message;
    },
    stopLoadingEventsStatistics: (state) => {
      state.isLoadingEventsStatistics = false;
    },
    //Detail Referenced RRPP
    startLoadingReferencedRrpp: (state) => {
      state.isLoadingStatisticsDetail = true;
    },
    setReferencedRrpp: (state, action) => {
      state.isLoadingStatisticsDetail = false;
      state.statisticsDetail = action.payload.data;
    },
    setEventsStatisticsDetailPaginated: (state, action) => {
      state.isLoadingStatisticsDetail = false;
      state.page = action.payload.page;
      state.statisticsDetailPaginated = action.payload.statisticsDetail.data;
    },
    setChangeStatisticsDetail: (state, action) => {
      state.changeStatisticsDetail = action.payload;
    },
    setErrorReferencedRrpp: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessageReferencedRrpp = message;
    },
    stopLoadingReferencedRrpp: (state) => {
      state.isLoadingStatisticsDetail = false;
    },
    //Detail Producer
    startLoadingStatisticsByProducer: (state) => {
      state.isLoadingStatisticsByProducer = true;
    },
    setStatisticsByProducer: (state, action) => {
      state.isLoadingStatisticsByProducer = false;
      state.statisticsByProducer = action.payload.data;
    },
    setStatisticsByProducerPaginated: (state, action) => {
      state.isLoadingStatisticsByProducer = false;
      state.page = action.payload.page;
      state.statisticsByProducerPaginated = action.payload.statisticsByProducer.data;
    },
    setChangeStatisticsByProducer: (state, action) => {
      state.changeStatisticsByProducer = action.payload;
    },
    setErrorStatisticsByProducer: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessageStatisticsByProducer = message;
    },
    stopLoadingStatisticsByProducer: (state) => {
      state.isLoadingStatisticsByProducer = false;
    },
    //Direct-rrpp
    startLoadingDirectRrpp: (state) => {
      state.isLoadingDirectRrpp = true;
    },
    setStatisticsDirectRrpp: (state, action) => {
      state.isLoadingDirectRrpp = false;
      state.statisticsDirectRrpp = action.payload.data;
    },
    setDirectRrppPaginated: (state, action) => {
      state.isLoadingDirectRrpp = false;
      state.page = action.payload.page;
      state.directRrppPaginated = action.payload.statisticsDirectRrpp.data;
    },
    setChangeDirectRrpp: (state, action) => {
      state.changeDirectRrpp = action.payload;
    },
    setErrorDirectRrpp: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessageDirectRrpp = message;
    },
    stopLoadingDirectRrpp: (state) => {
      state.isLoadingDirectRrpp = false;
    },
    //Loyalty
    startLoadingLoyalty: (state) => {
      state.isLoadingLoyalty = true;
    },
    setExchangesLoyalty: (state, action) => {
      state.isLoadingLoyalty = false;
      state.exchangesLoyalty = action.payload.data;
    },
    setLoyaltyPaginated: (state, action) => {
      state.isLoadingLoyalty = false;
      state.page = action.payload.page;
      state.loyaltyPaginated = action.payload.exchangesLoyalty.data;
    },
    setChangeLoyalty: (state, action) => {
      state.changeLoyalty = action.payload;
    },
    setErrorLoyalty: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessageLoyalty = message;
    },
    stopLoadingLoyalty: (state) => {
      state.isLoadingLoyalty = false;
    },
    //Gifts
    startLoadingStatisticsGift: (state) => {
      state.isLoadingStsGift = true;
    },
    setStatisticsGift: (state, action) => {
      state.isLoadingStsGift = false;
      state.statisticsGift = action.payload.data;
    },
    setGiftPaginated: (state, action) => {
      state.isLoadingStsGift = false;
      state.page = action.payload.page;
      state.stsGiftPaginated = action.payload.statisticsGift.data;
    },
    setChangeStatisticsGift: (state, action) => {
      state.changeStsGift = action.payload;
    },
    setErrorStatisticsGift: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessageStsGift = message;
    },
    setTypeEditonEvent: (state, action) => {
      state.typeEditonEvent = action.payload;
    },
    stopLoadingStatisticsGift: (state) => {
      state.isLoadingStsGift = false;
    },
  }
});

export const {
  startLoadingEvents,
  setEvents,
  setEventsPaginated,
  setError,
  setChangeEvent,
  setActiveEvent,
  setErrorEventsStatistics,
  setEventsStatistics,
  startLoadingEventsStatistics,
  setChangeEventStatistics,
  setEventsStatisticsPaginated,
  setReferencedRrpp,
  startLoadingReferencedRrpp,
  setChangeStatisticsDetail,
  setEventsStatisticsDetailPaginated,
  setErrorReferencedRrpp,
  startLoadingStatisticsByProducer,
  setStatisticsByProducer,
  setStatisticsByProducerPaginated,
  setChangeStatisticsByProducer,
  setErrorStatisticsByProducer,
  startLoadingDirectRrpp,
  setChangeDirectRrpp,
  setStatisticsDirectRrpp,
  setDirectRrppPaginated,
  setErrorDirectRrpp,
  startLoadingLoyalty,
  setChangeLoyalty,
  setExchangesLoyalty,
  setLoyaltyPaginated,
  setErrorLoyalty,
  setChangeStatisticsGift,
  setGiftPaginated,
  setStatisticsGift,
  setErrorStatisticsGift,
  startLoadingStatisticsGift,
  stopLoadingEvent,
  setTypeEditonEvent,
  setChangeEvtTktProcess,
  startLoadingEvtTktProcessByDU,
  stopLoadingEvtTktProcessByDU,
  setQuantityTkt,
  setSelectedTickets,
  stopLoadingDirectRrpp,
  stopLoadingEventsStatistics,
  stopLoadingLoyalty,
  stopLoadingReferencedRrpp,
  stopLoadingStatisticsByProducer,
  stopLoadingStatisticsGift,
} = eventSlice.actions;