import axiosMockAdapter from 'src/utils/mock';
import type { Redimidor } from 'src/models/redimidor';
import randomId from 'src/utils/randomId';

const urlMatch = new RegExp(/^\/api\/redimidores\//);

let redimidores: Redimidor[] = [
    {
      rolUsers: [
        {
          proLastName: 'mailinator ',
          id: 'TxgNazPbmCOc3TzrsFWSj2tmnvJ2',
          proName: 'guardia',
          proEmail: 'guardia@mailinator.com'
        },
        {
          proLastName: 'mailinator ',
          id: 'ITxCl2WFpIevFitxM1q9TGkZD9c2',
          proName: 'rrpp2',
          proEmail: 'rrpp2@mailinator.com'
        }
      ],
      rolDesc: 'Guardia que redime solo entradas',
      rolPermissions: [
        {
          id: 'Combo',
          label: 'Control Combo',
          selected: true
        },
        {
          id: 'Entrada',
          label: 'Control Entrada',
          selected: true
        },
        {
          label: 'Control Ticket',
          id: 'Ticket',
          selected: true
        }
      ],
      rolPlaces: [
        {
          plcAddress:
            'Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina',
          plcCoordinates: {
            _longitude: null,
            _latitude: null
          },
          id: 'lMT7mH5IqQ6ZtRQtWWkF',
          plcName: 'Keops'
        },
        {
          plcAddress: 'Av. Amadeo Sabattini 4591',
          plcCoordinates: {
            _longitude: null,
            _latitude: null
          },
          id: 'cvEHCGh7v83maxqi8fbx',
          plcName: 'Arco de Córdoba'
        }
      ],
      rolName: 'Guardia de Entradas',
      id: 'aMLCVQrF91ZOa5HSEH2A'
    },
    {
      rolUsers: [
        {
          proLastName: 'RRPP',
          id: 'cOFlxEsjKBdkTxgSqvcz5hyeIpE3',
          proName: 'RRPP',
          proEmail: 'rrpp@mailinator.com'
        }
      ],
      rolDesc: 'control de qr en barra',
      rolPermissions: [
        {
          id: 'Canje',
          label: 'Control Canje',
          selected: true
        },
        {
          id: 'Combo',
          label: 'Control Combo',
          selected: true
        }
      ],
      rolPlaces: [
        {
          plcAddress: 'Av. Amadeo Sabattini 4591',
          plcCoordinates: {
            _longitude: null,
            _latitude: null
          },
          id: 'cvEHCGh7v83maxqi8fbx',
          plcName: 'Arco de Córdoba'
        }
      ],
      rolName: 'Control barra',
      id: 'kSt3w8Y84IPsUxQcxReF'
    }
  ];

axiosMockAdapter.onGet('/api/redimidores').reply(200, { redimidores });

axiosMockAdapter.onDelete('/api/redimidores/delete').reply((config)=>{
  if(config.params.id){
    const index = redimidores.findIndex((redimidor) => redimidor.id === config.params.id);

    if (index !== -1) {
      redimidores.splice(index, 1);
  
      return [200, { message: 'Redimidor eliminado correctamente', redimidores }, { status: 200, statusText: 'OK' }];
    } else {
      return [404, { error: 'Redimidor no encontrado' }, { status: 404, statusText: 'Not Found', data: { message: 'Redimidor no encontrado' } }];
    }
  }
})

axiosMockAdapter.onDelete('/api/redimidores').reply((config)=>{
  const redimidorId = config.params.redimidorId
  const userId = config.params.userId

  if(redimidorId && userId){
    const redimidorIndex = redimidores.findIndex((redimidor) => redimidor.id === redimidorId);

    if (redimidorIndex !== -1) {
      redimidores[redimidorIndex].rolUsers = redimidores[redimidorIndex].rolUsers.filter(user => user.id !== userId) 
  
      return [200, { message: 'Redimidor eliminado correctamente', redimidores }, { status: 200, statusText: 'OK' }];
    } else {
      return [404, { error: 'Redimidor no encontrado' }, { status: 404, statusText: 'Not Found', data: { message: 'Redimidor no encontrado' } }];
    }
  }
})

axiosMockAdapter.onPost('/api/redimidores').reply((config)=>{
  const data = JSON.parse(config.data);
  const redimidorId = data.redimidorId
  const users = data.users

  if(redimidorId && users){
    const redimidorIndex = redimidores.findIndex((redimidor) => redimidor.id === redimidorId);

    if (redimidorIndex !== -1) {
      redimidores[redimidorIndex].rolUsers = [...redimidores[redimidorIndex].rolUsers, ...users]
  
      return [200, { message: 'Redimidor eliminado correctamente', redimidores }, { status: 200, statusText: 'OK' }];
    } else {
      return [404, { error: 'Redimidor no encontrado' }, { status: 404, statusText: 'Not Found', data: { message: 'Redimidor no encontrado' } }];
    }
  }
})
//deje ruta de api/roles porque ya existia redimidores en post
axiosMockAdapter.onPost('/api/roles').reply((config) => {
	console.log('config.data: ', config.data);
	const rolesData = JSON.parse(config.data);
	rolesData.id = randomId();
	redimidores.push(rolesData);
	return [201, redimidores];
});

axiosMockAdapter.onPut(urlMatch).reply((config) => {
	console.log('config.data: ', config.data);
  //const rolId = config.params.rolId;
	const rolesData = JSON.parse(config.data);
	const index = redimidores.findIndex((rol) => rol.id === rolesData.id);
  console.log('index: ', index);
	if (index !== -1) {
		redimidores[index] = rolesData;
		return [200, rolesData];
	} else {
		return [404, { error: 'Rol no encontrado' }];
	}
});