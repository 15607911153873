import axiosMockAdapter from 'src/utils/mock';
import type { Redimidor } from 'src/models/redimidor';
import randomId from 'src/utils/randomId';

const urlMatch = new RegExp(/^\/api\/roles\//);

let roles: Redimidor[] = [
	{
		"rolUsers": [
			{
				"proLastName": "mailinator ",
				"id": "TxgNazPbmCOc3TzrsFWSj2tmnvJ2",
				"proName": "guardia",
				"proEmail": "guardia@mailinator.com"
			},
			{
				"proLastName": "mailinator ",
				"id": "ITxCl2WFpIevFitxM1q9TGkZD9c2",
				"proName": "rrpp2",
				"proEmail": "rrpp2@mailinator.com"
			}
		],
		"rolDesc": "Guardia que redime solo entradas",
		"rolPermissions": [
			{
				"id": "Combo",
				"label": "Control Combo",
				"selected": true
			},
			{
				"id": "Entrada",
				"label": "Control Entrada",
				"selected": true
			},
			{
				"label": "Control Ticket",
				"id": "Ticket",
				"selected": true
			}
		],
		"rolPlaces": [
			{
				"plcAddress": "Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina",
				"plcCoordinates": {
					"_longitude": null,
					"_latitude": null
				},
				"id": "lMT7mH5IqQ6ZtRQtWWkF",
				"plcName": "Keops"
			},
			{
				"plcAddress": "Av. Amadeo Sabattini 4591",
				"plcCoordinates": {
					"_longitude": null,
					"_latitude": null
				},
				"id": "cvEHCGh7v83maxqi8fbx",
				"plcName": "Arco de Córdoba"
			}
		],
		"rolName": "Guardia de Entradas",
		"id": "aMLCVQrF91ZOa5HSEH2A"
	},
	{
		"rolUsers": [
			{
				"proLastName": "RRPP",
				"id": "cOFlxEsjKBdkTxgSqvcz5hyeIpE3",
				"proName": "RRPP",
				"proEmail": "rrpp@mailinator.com"
			}
		],
		"rolDesc": "control de qr en barra",
		"rolPermissions": [
			{
				"id": "Canje",
				"label": "Control Canje",
				"selected": true
			},
			{
				"id": "Combo",
				"label": "Control Combo",
				"selected": true
			}
		],
		"rolPlaces": [
			{
				"plcAddress": "Av. Amadeo Sabattini 4591",
				"plcCoordinates": {
					"_longitude": null,
					"_latitude": null
				},
				"id": "cvEHCGh7v83maxqi8fbx",
				"plcName": "Arco de Córdoba"
			}
		],
		"rolName": "Control barra",
		"id": "kSt3w8Y84IPsUxQcxReF"
	}
];

axiosMockAdapter.onGet('/api/roles').reply(200, { roles });

axiosMockAdapter.onPost('/api/roles').reply((config) => {
	console.log('config.data: ', config.data);
	const rolesData = JSON.parse(config.data);
	rolesData.id = randomId();
	roles.push(rolesData);
	return [201, rolesData];
});

axiosMockAdapter.onPut(urlMatch).reply((config) => {
	console.log('config.data: ', config.data);
	const rolesData = JSON.parse(config.data);
	const index = roles.findIndex((event) => event.id === rolesData.id);
	if (index !== -1) {
		roles[index] = rolesData;
		return [200, rolesData];
	} else {
		return [404, { error: 'Rol no encontrado' }];
	}
});

axiosMockAdapter.onDelete('/api/roles/delete').reply((config) => {

	console.log('config.data: ', config.data);
	const roleDelete = JSON.parse(config.data);
	const index = roles.findIndex((event) => event.id === roleDelete.id);

	if (index !== -1) {
		roles.splice(index, 1);

		return [200, { message: 'Rol eliminado correctamente' }, { status: 200, statusText: 'OK' }];
	} else {
		return [404, { error: 'Rol no encontrado' }, { status: 404, statusText: 'Not Found', data: { message: 'Rol no encontrado' } }];
	}
});