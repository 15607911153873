import { startLoading, stopLoading, setUserAuth, setError } from './authSlice';
import { AppDispatch } from 'src/store';
import axiosInst from 'src/utils/axiosCreate';

export const usersByEmailOrDU = (userEmail, token?: string) => {
  return async (dispatch: AppDispatch) => {
    const userData = {
      usersDU: '',
      usersEmail: userEmail
    };
    try {
      dispatch(startLoading());
      let resp;
      if (token) {
        resp = await axiosInst.post(`/users/usersByEmailOrDU`, userData, {
          headers: {
            authorizationqp: `Bearer ${token}`
          }
        });
      } else {
        resp = await axiosInst.post(`/users/usersByEmailOrDU`, userData);
      }
      if (!resp) {
        dispatch(setError({ status: resp.status, message: resp.statusText }));
        dispatch(stopLoading());
        return null;
      } else {
        const user = resp.data.data.users[0];
        dispatch(setUserAuth({ user, roles: user.proRoles }));
        dispatch(stopLoading());
        return resp.data;
      }
    } catch (error) {
      const resp = error.response;
      dispatch(setError({ status: resp.status, message: resp.data.message }));
      dispatch(stopLoading());
      throw error;
    }
  };
};
