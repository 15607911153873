import { subMonths } from "date-fns";

export const getCurrentDate = (): Date => {
    const currentDate = new Date();
    return currentDate;
  };

  export const getDateOneMonthAgo = (): Date => {
    const currentDate = new Date();
    const previousMonthDate = subMonths(currentDate, 1);
    return previousMonthDate;
  };