import axiosMockAdapter from 'src/utils/mock';
import type { User, UserPoint } from 'src/models/user';
import { applyPagination } from 'src/utils/pagination';
import {
    applyFilters,
    filterByBirthday,
    filterByGender,
    filterByRoles,
    filterByTags
} from 'src/utils/filterUsers';

const urlMatch = new RegExp(/^\/api\/users\//);

let users: User[] = [
    {
        id: 's9JM1nPEAfN6zJkolOMi72Fzyd52',
        init_date: 1689092519,
        last_read: 1689092519,
        phoneCode: null,
        proAcceptTerms: 1,
        proAcceptWhatsapp: 0,
        proBirthDate: '2000-03-27T20:03:21.000Z',
        proDNI: null,
        proDniType: 1,
        proEmail: 'testkeps@mailinator.com',
        proGender: 'female',
        proInactiveAccount: 0,
        proInstagram: null,
        proLastName: 'dasdasd',
        proLoyalyMember: null,
        proName: 'adsada',
        proPhone: '35121234567',
        proRoles: [],
        proRolesRedimidor: [],
        proSignupState: null,
        proTags: [],
        proValidatedPhone: null,
        proVerified: 0,
        tipo: 'user',
        proVerificatorUser: null,
        proVerificationTime: null,
        proAccountState: null
    },
    {
        id: 'ITxCl2WFpIevFitxM1q9TGkZD9c2',
        init_date: 1675110485,
        last_read: 1675110485,
        phoneCode: '880965',
        proAcceptTerms: 1,
        proAcceptWhatsapp: 1,
        proBirthDate: null,
        proDNI: null,
        proDniType: null,
        proEmail: 'rrpp2@mailinator.com',
        proGender: null,
        proInactiveAccount: 0,
        proInstagram: 'rrpp2',
        proLastName: 'mailinator ',
        proLoyalyMember: null,
        proName: 'rrpp2',
        proPhone: '3512345678',
        proRoles: [
            {
                id: 'Rrpp'
            },
            {
                id: 'Redimidor'
            }
        ],
        proRolesRedimidor: {},
        proSignupState: 'complete',
        proTags: [],
        proValidatedPhone: '3512345678',
        proVerified: 1,
        tipo: 'user',
        proVerificatorUser: 'superadm@mailinator.com',
        proVerificationTime: '2023-03-27T18:16:12.000Z',
        proAccountState: null
    },
    {
        id: 'SdQfVSTZjsdZYMKQJ9yg0z5lE1F2',
        init_date: 1673821286,
        last_read: 1673821491,
        phoneCode: '255968',
        proAcceptTerms: 1,
        proAcceptWhatsapp: 0,
        proBirthDate: '2013-01-13T20:03:21.000Z',
        proDNI: '24',
        proDniType: 1,
        proEmail: '24@mailinator.com',
        proGender: null,
        proInactiveAccount: 0,
        proInstagram: '24',
        proLastName: 'mailinator ',
        proLoyalyMember: null,
        proName: '24',
        proPhone: '3512345678',
        proRoles: [],
        proRolesRedimidor: [],
        proSignupState: 'complete',
        proTags: [],
        proValidatedPhone: '3512345678',
        proVerified: 0,
        tipo: 'user',
        proVerificatorUser: 'superadm@mailinator.com',
        proVerificationTime: null,
        proAccountState: null
    },
    {
        id: '0MpKxdgof3Oi5mlDr67XBHcI2Pr1',
        init_date: 1673820793,
        last_read: 1673820981,
        phoneCode: '253456',
        proAcceptTerms: 1,
        proAcceptWhatsapp: 0,
        proBirthDate: '2013-01-10T20:03:21.000Z',
        proDNI: '23',
        proDniType: 1,
        proEmail: '23@mailinator.com',
        proGender: null,
        proInactiveAccount: 1,
        proInstagram: '23',
        proLastName: 'mailinator ',
        proLoyalyMember: null,
        proName: '23',
        proPhone: '3512345678',
        proRoles: [],
        proRolesRedimidor: [],
        proSignupState: 'complete',
        proTags: [
            {
                id: 'v2UW8fXRZhMlKgKAGRHf',
                tagName: 'VIP',
                tagColor: '#e4c70c',
                isTagVisible: true
            }
        ],
        proValidatedPhone: '3512345678',
        proVerified: 0,
        tipo: 'user',
        proVerificatorUser: 'superadm@mailinator.com',
        proVerificationTime: null,
        proAccountState: null
    },
    {
        id: 'qpEIhMqjAbbSas55FI3B7YZ5zEe2',
        init_date: 1673820325,
        last_read: 1673820325,
        phoneCode: null,
        proAcceptTerms: 1,
        proAcceptWhatsapp: 0,
        proBirthDate: null,
        proDNI: '22',
        proDniType: 1,
        proEmail: '22@mailinator.com',
        proGender: null,
        proInactiveAccount: 0,
        proInstagram: '22',
        proLastName: 'mailinator ',
        proLoyalyMember: null,
        proName: '22',
        proPhone: '3512345678',
        proRoles: [],
        proRolesRedimidor: [],
        proSignupState: null,
        proTags: [],
        proValidatedPhone: null,
        proVerified: 1,
        tipo: null,
        proVerificatorUser: 'superadm@mailinator.com',
        proVerificationTime: '2023-03-27T20:03:21.000Z',
        proAccountState: null
    },
    {
        id: 'ySX6wXZeZpZbjSNkfVEOGLl7ITK2',
        init_date: 1673819811,
        last_read: 1673819811,
        phoneCode: '543442',
        proAcceptTerms: 1,
        proAcceptWhatsapp: 0,
        proBirthDate: null,
        proDNI: '19',
        proDniType: 1,
        proEmail: '19@mailinator.com',
        proGender: 'female',
        proInactiveAccount: 0,
        proInstagram: '19',
        proLastName: 'mailinator',
        proLoyalyMember: null,
        proName: '19',
        proPhone: '3212345678',
        proRoles: [
            {
                id: 'Redimidor'
            }
        ],
        proRolesRedimidor: [],
        proSignupState: 'complete',
        proTags: [
            {
                id: 'vljlZ38kV0qalFulP3Ax',
                tagName: 'VERIFICADO',
                tagColor: '#1fe022',
                isTagVisible: true
            },
            {
                id: 'v2UW8fXRZhMlKgKAGRHf',
                tagName: 'VIP',
                tagColor: '#e4c70c',
                isTagVisible: true
            }
        ],
        proValidatedPhone: '3212345678',
        proVerified: 1,
        tipo: 'user',
        proVerificatorUser: 'superadm@mailinator.com',
        proVerificationTime: '2023-03-27T23:56:07.000Z',
        proAccountState: null
    },
    {
        id: 'Bh0QohuX6tWzSE8r8PSkPBIJJDL2',
        init_date: 1673732818,
        last_read: 1673732818,
        phoneCode: '500053',
        proAcceptTerms: 1,
        proAcceptWhatsapp: 0,
        proBirthDate: null,
        proDNI: '20',
        proDniType: 1,
        proEmail: '20@mailinator.com',
        proGender: null,
        proInactiveAccount: 1,
        proInstagram: '20',
        proLastName: 'mailinator ',
        proLoyalyMember: null,
        proName: '20',
        proPhone: '3512345678',
        proRoles: [],
        proRolesRedimidor: [],
        proSignupState: 'complete',
        proTags: [],
        proValidatedPhone: '3512345678',
        proVerified: 0,
        tipo: 'user',
        proVerificatorUser: null,
        proVerificationTime: null,
        proAccountState: null
    },
    {
        id: 'TxgNazPbmCOc3TzrsFWSj2tmnvJ2',
        init_date: 1670270659,
        last_read: 1670270659,
        phoneCode: '317693',
        proAcceptTerms: 1,
        proAcceptWhatsapp: 0,
        proBirthDate: null,
        proDNI: null,
        proDniType: 1,
        proEmail: 'guardia@mailinator.com',
        proGender: null,
        proInactiveAccount: 0,
        proInstagram: null,
        proLastName: 'mailinator ',
        proLoyalyMember: null,
        proName: 'guardia',
        proPhone: '3516508432',
        proRoles: [
            {
                id: 'Redimidor'
            }
        ],
        proRolesRedimidor: {
            aMLCVQrF91ZOa5HSEH2A: {
                rolDesc: 'Guardia que redime solo entradas',
                rolName: 'Guardia de Entradas',
                rolPlaces: [
                    {
                        id: 'lMT7mH5IqQ6ZtRQtWWkF',
                        plcName: 'Keops',
                        plcAddress:
                            'Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina',
                        plcCoordinates: {
                            _latitude: -31.4024536,
                            _longitude: -64.46538029999999
                        }
                    }
                ],
                rolPermissions: [
                    {
                        id: 'Combo',
                        label: 'Control Combo',
                        selected: true
                    },
                    {
                        id: 'Entrada',
                        label: 'Control Entrada',
                        selected: true
                    },
                    {
                        id: 'Ticket',
                        label: 'Control Ticket',
                        selected: true
                    }
                ]
            }
        },
        proSignupState: 'complete',
        proTags: [],
        proValidatedPhone: '3516508432',
        proVerified: 1,
        tipo: 'user',
        proVerificatorUser: 'superadm@mailinator.com',
        proVerificationTime: '2022-12-08T17:10:33.000Z',
        proAccountState: null
    },
    {
        tipo: 'user',
        proDniType: 1,
        proValidatedPhone: '3516508432',
        proLastName: 'Gp',
        proLoyalyMember: null,
        proPhone: '3516508432',
        proInstagram: 'Guardia.insta',
        phoneCode: '635112',
        proBirthDate: null,
        proAcceptTerms: 1,
        proName: 'Guardia',
        proEmail: 'guardia@mailinator.com',
        last_read: 1663103241,
        init_date: 1663101783,
        proAcceptWhatsapp: 0,
        proVerificationTime: null,
        proDNI: '22222222',
        proAccountState: null,
        proVerificatorUser: null,
        proSignupState: 'complete',
        proRoles: [
            {
                id: 'Redimidor'
            }
        ],
        proGender: 'male',
        proVerified: 0,
        proInactiveAccount: false,
        proTags: [
            {
                isTagVisible: false,
                tagColor: '#1fe022',
                id: 'LxD0adkiV6Foy1kQif3h',
                tagName: 'MALE',
                assignTime: {
                    _seconds: 1715693988,
                    _nanoseconds: 153000000
                }
            },
            {
                isTagVisible: false,
                tagColor: '#1fe022',
                id: 'DXiUsVbsCVfhunCLqxv5',
                tagName: 'SEPTIEMBRE',
                assignTime: {
                    _seconds: 1715693988,
                    _nanoseconds: 154000000
                }
            }
        ],
        proRolesRedimidor: {
            sfOYlh5naDY9VuBEtHj4: {
                rolDesc: 'Guardia que redime solo entradas',
                rolPermissions: [
                    {
                        label: 'Control Canje',
                        id: 'Canje',
                        selected: true
                    },
                    {
                        id: 'Combo',
                        label: 'Control Combo',
                        selected: true
                    },
                    {
                        id: 'Entrada',
                        label: 'Control Entrada',
                        selected: true
                    },
                    {
                        label: 'Control Ticket',
                        id: 'Ticket',
                        selected: true
                    },
                    {
                        id: 'Traslado',
                        label: 'Control Traslado',
                        selected: true
                    }
                ],
                rolName: 'Guardia de Entradas',
                rolPlaces: [
                    {
                        plcAddress: 'Av Colon 4800',
                        plcCoordinates: {
                            _longitude: -64.19200679418428,
                            _latitude: -31.423168215382606
                        },
                        id: '3Soia5LvKAdaG4birkyJ',
                        plcName: 'LugarA'
                    },
                    {
                        plcAddress:
                            'Int. Ramón Bautista Mestre Nte. 1129, Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.197164,
                            _latitude: -31.4059025
                        },
                        id: 'CXOK1NBkD5krWuUHdtb6',
                        plcName: 'Plaza de la Musica - Cordoba'
                    },
                    {
                        plcAddress:
                            'Quebrada De Las Rosas, Chancay 600, X5003 EOL, Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.2508756,
                            _latitude: -31.3881249
                        },
                        id: 'ywsR5iSXsS3dVX4KhMTP',
                        plcName: 'Alto Botánico'
                    },
                    {
                        plcAddress: 'Av. Cárcano s/n, Chateau Carreras, Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.246113,
                            _latitude: -31.3688642
                        },
                        id: '9q85mVywqVJP0cAb9Z8o',
                        plcName: 'KEMPES'
                    }
                ]
            },
            '1MBkp9oLrOQt1AuUIERE': {
                rolDesc: 'Este es un rol de prueba',
                rolPermissions: [
                    {
                        id: 'Combo',
                        label: 'Control Combo',
                        selected: true
                    },
                    {
                        label: 'Control Entrada',
                        id: 'Entrada',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress: 'Av Colon 4800',
                        plcCoordinates: {
                            _longitude: -64.19200679418428,
                            _latitude: -31.423168215382606
                        },
                        id: '3Soia5LvKAdaG4birkyJ',
                        plcName: 'LugarA'
                    }
                ],
                rolName: 'Rol de Prueba A'
            },
            '485MPTyxa8mUQEFK9NHo': {
                rolDesc: 'nuevo rol',
                rolPermissions: [
                    {
                        id: 'Canje',
                        label: 'Control Canje',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress: 'Av. Colón, X5000EPZ Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.1957887,
                            _latitude: -31.40931789999999
                        },
                        id: '8q8S6IhgKddaEOXNyTDL',
                        plcName: 'Probando nueva ubicación'
                    }
                ],
                rolName: 'nuevo rol'
            },
            '5QHOtX0xsBRHE8pCKn75': {
                rolDesc: 'nuevo rol',
                rolPermissions: [
                    {
                        id: 'Canje',
                        label: 'Control Canje',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress: 'Av. Colón, X5000EPZ Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.1957887,
                            _latitude: -31.40931789999999
                        },
                        id: '8q8S6IhgKddaEOXNyTDL',
                        plcName: 'Probando nueva ubicación'
                    }
                ],
                rolName: 'new role'
            },
            YzLQoZuzsUnV1UO2zQMf: {
                rolDesc: 'test rol',
                rolPermissions: [
                    {
                        id: 'Canje',
                        label: 'Control Canje',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress: 'Av. Colón, X5000EPZ Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.1957887,
                            _latitude: -31.40931789999999
                        },
                        id: '8q8S6IhgKddaEOXNyTDL',
                        plcName: 'Probando nueva ubicación'
                    }
                ],
                rolName: 'test rol'
            },
            '0v8Aey2u170wf1mw2XYS': {
                rolDesc: 'test rol',
                rolPermissions: [
                    {
                        id: 'Canje',
                        label: 'Control Canje',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress: 'Av. Colón, X5000EPZ Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.1957887,
                            _latitude: -31.40931789999999
                        },
                        id: '8q8S6IhgKddaEOXNyTDL',
                        plcName: 'Probando nueva ubicación'
                    }
                ],
                rolName: 'test rol'
            },
            gLZvJPFTnx5dFag8qC3o: {
                rolDesc: 'test rol nuevo',
                rolPermissions: [
                    {
                        id: 'Canje',
                        label: 'Control Canje',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress: 'Av. Colón, X5000EPZ Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.1957887,
                            _latitude: -31.40931789999999
                        },
                        id: '8q8S6IhgKddaEOXNyTDL',
                        plcName: 'Probando nueva ubicación'
                    }
                ],
                rolName: 'test rol nuevo'
            },
            btga5gGkS6JRgcodIKQw: {
                rolDesc: 'test rol',
                rolPermissions: [
                    {
                        id: 'Canje',
                        label: 'Control Canje',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress: 'Av. Colón, X5000EPZ Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.1957887,
                            _latitude: -31.40931789999999
                        },
                        id: '8q8S6IhgKddaEOXNyTDL',
                        plcName: 'Probando nueva ubicación'
                    }
                ],
                rolName: 'test rol'
            },
            v5z9GFCPvQJ9DRiaepNA: {
                rolDesc: 'test rol',
                rolPermissions: [
                    {
                        id: 'Canje',
                        label: 'Control Canje',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress: 'Av. Colón, X5000EPZ Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.1957887,
                            _latitude: -31.40931789999999
                        },
                        id: '8q8S6IhgKddaEOXNyTDL',
                        plcName: 'Probando nueva ubicación'
                    }
                ],
                rolName: 'test rol'
            },
            TXaNzTsKt3WLK63oAsFz: {
                rolDesc: 'otro rol de test',
                rolPermissions: [
                    {
                        id: 'Combo',
                        label: 'Control Combo',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress: 'Av. Colón, X5000EPZ Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.1957887,
                            _latitude: -31.40931789999999
                        },
                        id: '8q8S6IhgKddaEOXNyTDL',
                        plcName: 'Probando nueva ubicación'
                    }
                ],
                rolName: 'otro rol de test'
            },
            UJ2yHizqfz8dx0UZxZzw: {
                rolDesc: 'coordenadas seteadas',
                rolPermissions: [
                    {
                        id: 'Ticket',
                        label: 'Control Ticket',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress: 'Av. Colón, X5000EPZ Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.1957887,
                            _latitude: -31.40931789999999
                        },
                        id: '8q8S6IhgKddaEOXNyTDL',
                        plcName: 'Probando nueva ubicación'
                    }
                ],
                rolName: 'test con lugar'
            },
            '3j9G27GfD970LNxQH2hI': {
                rolDesc: 'test',
                rolPermissions: [
                    {
                        id: 'Entrada',
                        label: 'Control Entrada',
                        selected: true
                    }
                ],
                rolPlaces: [
                    {
                        plcAddress:
                            'Roque Sáenz Peña Centro, Villa Carlos Paz, Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: null,
                            _latitude: null
                        },
                        id: 'lMT7mH5IqQ6ZtRQtWWkF',
                        plcName: 'Keops'
                    },
                    {
                        plcAddress: 'Mauricio Yadarola 1699, Córdoba, Argentina',
                        plcCoordinates: {
                            _longitude: -64.158924,
                            _latitude: -31.392763
                        },
                        id: 'QL3H4j0htD8tM0UkWih1',
                        plcName: 'Forja Centro de Eventos'
                    }
                ],
                rolName: 'test '
            }
        },
        id: '62W13EKmKAXqYZjjrbfIsHGF70y1'
    }
];

let userPoints: UserPoint = {
    data: {
        "points": 2200,
        "movements": [
            {
                "mo_date": {
                    "_seconds": 1699394426.3,
                    "_nanoseconds": 0
                },
                "mo_points": 500,
                "mo_movtype": "s",
                "mo_description": "Sumaste puntos por tu compra.",
                "mo_title": "Sumaste puntos por tu compra.",
                "uid": 14353
            },
            {
                "mo_date": {
                    "_seconds": 1699394298.565,
                    "_nanoseconds": 0
                },
                "mo_points": 500,
                "mo_movtype": "s",
                "mo_description": "Sumaste puntos por tu compra.",
                "mo_title": "Sumaste puntos por tu compra.",
                "uid": 14352
            },
            {
                "mo_date": {
                    "_seconds": 1699392971.432,
                    "_nanoseconds": 0
                },
                "mo_points": 500,
                "mo_movtype": "s",
                "mo_description": "Sumaste puntos por tu compra.",
                "mo_title": "Sumaste puntos por tu compra.",
                "uid": 14351
            },
            {
                "mo_date": {
                    "_seconds": 1699391821.679,
                    "_nanoseconds": 0
                },
                "mo_points": 500,
                "mo_movtype": "s",
                "mo_description": "Sumaste puntos por tu compra.",
                "mo_title": "Sumaste puntos por tu compra.",
                "uid": 14350
            },
            {
                "mo_date": {
                    "_seconds": 1699391774.262,
                    "_nanoseconds": 0
                },
                "mo_points": 200,
                "mo_movtype": "s",
                "mo_description": "Sumaste puntos por tu compra.",
                "mo_title": "Sumaste puntos por tu compra.",
                "uid": 14349
            },
            {
                "mo_date": {
                    "_seconds": 1699391774.262,
                    "_nanoseconds": 0
                },
                "mo_points": 200,
                "mo_movtype": "s",
                "mo_description": "Sumaste puntos por tu compra.",
                "mo_title": "Sumaste puntos por tu compra.",
                "uid": 14349
            }
        ]
    }
};

axiosMockAdapter.onGet('/api/users').reply((config) => {
    if (!config.params) {
        return [200, { users }];
    }

    const { from, size } = config.params.limit;
    const { birthdate, gender, onlyDeleted, roles, search, tags } =
        config.params.filter;
    const { desc, field } = config.params.order;
    const [month, dayFrom, dayTo] = birthdate;
    const page = from / size;
    let filtered = users;

    if (search !== '')
        filtered = applyFilters(filtered, search, [
            'proEmail',
            'proName',
            'proLastName',
            'proDNI',
            'proPhone'
        ]);
    if (roles.length > 0) filtered = filterByRoles(filtered, roles);
    if (gender !== '') filtered = filterByGender(filtered, gender);
    if (tags.length > 0) filtered = filterByTags(filtered, tags);
    if (month !== '' && dayFrom !== '' && dayTo !== '')
        filtered = filterByBirthday(filtered, month, dayFrom, dayTo);
    if (onlyDeleted)
        filtered = users.filter((user) => user.proInactiveAccount === 1);

    if (field) {
        filtered = filtered.sort((a, b) => {
            let aValue = a[field];
            let bValue = b[field];

            if (aValue === null) return desc ? 1 : -1;
            if (bValue === null) return desc ? -1 : 1;

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                // comparo strings
                return desc
                    ? -aValue.localeCompare(bValue)
                    : aValue.localeCompare(bValue);
            } else {
                // para comparar numeros y fechas
                return desc ? bValue - aValue : aValue - bValue;
            }
        });
    }

    const data = applyPagination(filtered, page, size);
    return [200, { data, filteredUsers: filtered, total: users.length }];
});

axiosMockAdapter.onGet('/api/userPoints').reply(200, { userPoints });

axiosMockAdapter.onDelete(urlMatch).reply((config) => {
    const userDelete = JSON.parse(config.data);
    const index = users.findIndex((user) => user.id === userDelete.id);
    if (index !== -1) {
        users.splice(index, 1);
        return [
            200,
            { message: 'Usuario eliminado correctamente' },
            { status: 200, statusText: 'OK' }
        ];
    } else {
        return [
            404,
            { error: 'Usuario no encontrado' },
            {
                status: 404,
                statusText: 'Not Found',
                data: { message: 'Usuario no encontrado' }
            }
        ];
    }
});

axiosMockAdapter.onPost('/api/users/tags').reply((config) => {
    const data = JSON.parse(config.data);
    if (data) {
        const { selectedUsers, selectedTags } = data;
        selectedUsers.forEach((userId) => {
            const userToUpdate = users.find((user) => user.id === userId);
            if (userToUpdate) {
                selectedTags.forEach((tag) => {
                    if (!userToUpdate.proTags.some((t) => t.id === tag.id)) {
                        userToUpdate.proTags.push(tag);
                    }
                });
            }
        });
    }
    return [
        200,
        { message: 'Tags agregados a usuarios', users },
        { status: 200, statusText: 'OK' }
    ];
});

axiosMockAdapter.onPut(urlMatch).reply((config) => {
    const userData = JSON.parse(config.data);
    const userId = userData.id;
    const index = users.findIndex((user) => user.id === userId);
    if (index === -1) {
        return [
            404,
            { error: 'Usuario no encontrado' },
            {
                status: 404,
                statusText: 'Not Found',
                data: { message: 'Usuario no encontrado' }
            }
        ];
    }
    users[index] = userData;
    return [200, userData];
});

axiosMockAdapter.onDelete('/api/users/tags').reply((config) => {
    const { userId, tagId } = config.params;
    const userIndex = users.findIndex((user) => user.id === userId);

    if (userIndex !== -1) {
        const user = users[userIndex];
        const tagIndex = user.proTags.findIndex((tag) => tag.id === tagId);

        if (tagIndex !== -1) {
            user.proTags.splice(tagIndex, 1);

            return [
                200,
                { message: 'Tag eliminado correctamente', user },
                { status: 200, statusText: 'OK' }
            ];
        } else {
            return [
                404,
                { error: 'Tag no encontrado en el usuario' },
                {
                    status: 404,
                    statusText: 'Not Found',
                    data: { message: 'Tag no encontrado en el usuario' }
                }
            ];
        }
    } else {
        return [
            404,
            { error: 'Usuario no encontrado' },
            {
                status: 404,
                statusText: 'Not Found',
                data: { message: 'Usuario no encontrado' }
            }
        ];
    }
});

axiosMockAdapter.onDelete('/api/users/tags/all').reply((config) => {
    const { tagId } = config.params;
    if (tagId) {
        users.map((user) => {
            const tagIndex = user.proTags.findIndex((tag) => tag.id === tagId);
            if (tagIndex !== -1) {
                user.proTags.splice(tagIndex, 1);
            }
        });
        return [
            200,
            { message: 'Tags eliminados correctamente', users },
            { status: 200, statusText: 'OK' }
        ];
    } else {
        return [
            404,
            { error: 'TagId vacío' },
            { status: 404, statusText: 'Not Found', data: { message: 'TagId vacío' } }
        ];
    }
});

axiosMockAdapter.onPatch('/api/users/verify').reply((config) => {
    const { userId, proVerified } = config.params;

    const index = users.findIndex((user) => user.id === userId);
    if (index !== -1) {
        users[index].proVerified = proVerified;
        return [
            200,
            { message: 'User verification updated successfully', user: users[index] }
        ];
    } else {
        return [404, { error: 'User not found' }];
    }
});
