import axiosMockAdapter from 'src/utils/mock';
import { Ticket } from "src/models/ticket";

let tickets : Ticket[] = [
  {
    "items": [
      {
        "id": "MpkTLs45zf2wbXw1O787",
        "tktBuyDate": "2024-05-13T22:32:51.000Z",
        "tktBuyOperationId": "7b45ccff70",
        "tktBuyerDU": "42303663",
        "tktBuyerEmail": "matiasmorelliguerra@gmail.com",
        "tktBuyerName": "Matias Morelli",
        "tktBuyerLastName": "Morelli",
        "tktBuyerName2": "Matias",
        "tktCategory": "Entrada",
        "tktColor": "#be38f3",
        "tktDisplayName": "GENERAL ANTICIPADA - DE 2AM a 5AM",
        "tktOrigin": "rrppsell",
        "tktOwnerDU": "9803663",
        "tktOwnerEmail": "matiasmorelliguerra@gmail.com",
        "tktOwnerLastName": "Morelli",
        "tktOwnerName": "Matias Morelli",
        "tktOwnerName2": "Matias",
        "tktState": "already_used",
        "tktPrice": 8000,
        "tktPriceProducer": 8000,
        "tktRrppComision": 0,
        "tktType": "IVzxuo4Mbc3O7VVjtdyH",
        "tktSoldOut": null,
        "tktTags": null,
        "tktOutdateDate": "2024-05-19T08:00:00.000Z",
        "tktComboSubTickets": [],
        "createEmail": "clemenroberts@gmail.com",
        "createName": "Clemente",
        "createLastName": "Roberts",
        "rrppEmail": "clemenroberts@gmail.com",
        "rrppName": "Clemente",
        "rrppLastName": "Roberts",
        "producerEmail": null,
        "producerName": null,
        "producerLastName": null,
        "tktInvitationDU": null,
        "tktControlUser": null,
        "tktControlUserEmail": null,
        "tktControlTime": null,
        "tktControlType": null
      }
    ],
    "size": 1
  },
  {
    "items": [
      {
        "id": "MpkTLs45zf5tyXw1O787",
        "tktBuyDate": "2024-05-13T22:32:51.000Z",
        "tktBuyOperationId": "8l41ccfj70",
        "tktBuyerDU": "123456",
        "tktBuyerEmail": "test@gmail.com",
        "tktBuyerName": "test 1",
        "tktBuyerLastName": "test",
        "tktBuyerName2": "1",
        "tktCategory": "Entrada",
        "tktColor": "#1fe022",
        "tktDisplayName": "GENERAL ANTICIPADA - DE 2AM a 5AM",
        "tktOrigin": "rrppsell",
        "tktOwnerDU": "63442244",
        "tktOwnerEmail": "test@gmail.com",
        "tktOwnerLastName": "1",
        "tktOwnerName": "test",
        "tktOwnerName2": "test",
        "tktState": "not_used",
        "tktPrice": 4000,
        "tktPriceProducer": 4000,
        "tktRrppComision": 0,
        "tktType": "IVzxuokklly3O7VVjtdyH",
        "tktSoldOut": null,
        "tktTags": null,
        "tktOutdateDate": "2024-04-19T08:00:00.000Z",
        "tktComboSubTickets": [],
        "createEmail": "prueba@gmail.com",
        "createName": "prueba",
        "createLastName": "2",
        "rrppEmail": "prueba@gmail.com",
        "rrppName": "asasa",
        "rrppLastName": "fgfgf",
        "producerEmail": null,
        "producerName": null,
        "producerLastName": null,
        "tktInvitationDU": null,
        "tktControlUser": null,
        "tktControlUserEmail": null,
        "tktControlTime": null,
        "tktControlType": null
      }
    ],
    "size": 2
  },
  {
    "items": [
      {
        "id": "MpkTLs45zf5tyXw1O787",
        "tktBuyDate": "2024-05-13T22:32:51.000Z",
        "tktBuyOperationId": "8l41ccfj70",
        "tktBuyerDU": "123456",
        "tktBuyerEmail": "test@gmail.com",
        "tktBuyerName": "test 1",
        "tktBuyerLastName": "test",
        "tktBuyerName2": "1",
        "tktCategory": "Entrada",
        "tktColor": "#1fe022",
        "tktDisplayName": "GENERAL ANTICIPADA - DE 2AM a 5AM",
        "tktOrigin": "rrppsell",
        "tktOwnerDU": "63442244",
        "tktOwnerEmail": "test@gmail.com",
        "tktOwnerLastName": "1",
        "tktOwnerName": "test",
        "tktOwnerName2": "test",
        "tktState": "not_used",
        "tktPrice": 4000,
        "tktPriceProducer": 4000,
        "tktRrppComision": 0,
        "tktType": "IVzxuokklly3O7VVjtdyH",
        "tktSoldOut": null,
        "tktTags": null,
        "tktOutdateDate": "2024-04-19T08:00:00.000Z",
        "tktComboSubTickets": [],
        "createEmail": "prueba@gmail.com",
        "createName": "prueba",
        "createLastName": "2",
        "rrppEmail": "prueba@gmail.com",
        "rrppName": "asasa",
        "rrppLastName": "fgfgf",
        "producerEmail": null,
        "producerName": null,
        "producerLastName": null,
        "tktInvitationDU": null,
        "tktControlUser": null,
        "tktControlUserEmail": null,
        "tktControlTime": null,
        "tktControlType": null
      }
    ],
    "size": 2
  },
  {
    "items": [
      {
        "id": "ABC123",
        "tktBuyDate": "2024-05-14T22:32:51.000Z",
        "tktBuyOperationId": "XYZ456",
        "tktBuyerDU": "654321",
        "tktBuyerEmail": "example@gmail.com",
        "tktBuyerName": "John",
        "tktBuyerLastName": "Doe",
        "tktBuyerName2": "1",
        "tktCategory": "VIP",
        "tktColor": "#ff0000",
        "tktDisplayName": "VIP Ticket",
        "tktOrigin": "producersell",
        "tktOwnerDU": "987654",
        "tktOwnerEmail": "john.doe@example.com",
        "tktOwnerLastName": "Doe",
        "tktOwnerName": "John",
        "tktOwnerName2": "Doe",
        "tktState": "already_used",
        "tktPrice": 10000,
        "tktPriceProducer": 8000,
        "tktRrppComision": 2000,
        "tktType": "XYZ123",
        "tktSoldOut": null,
        "tktTags": null,
        "tktOutdateDate": "2024-05-30T08:00:00.000Z",
        "tktComboSubTickets": [],
        "createEmail": "admin@example.com",
        "createName": "Admin",
        "createLastName": "User",
        "rrppEmail": "rrpp@example.com",
        "rrppName": "Jane",
        "rrppLastName": "Doe",
        "producerEmail": null,
        "producerName": null,
        "producerLastName": null,
        "tktInvitationDU": null,
        "tktControlUser": null,
        "tktControlUserEmail": null,
        "tktControlTime": null,
        "tktControlType": null
      }
    ],
    "size": 3
  },
  {
    "items": [
      {
        "id": "DEF789",
        "tktBuyDate": "2024-05-15T22:32:51.000Z",
        "tktBuyOperationId": "GHI012",
        "tktBuyerDU": "987654",
        "tktBuyerEmail": "user@example.com",
        "tktBuyerName": "Alice",
        "tktBuyerLastName": "Smith",
        "tktBuyerName2": "2",
        "tktCategory": "VIP",
        "tktColor": "#0000ff",
        "tktDisplayName": "VIP Access",
        "tktOrigin": "invitation",
        "tktOwnerDU": "123456",
        "tktOwnerEmail": "alice.smith@example.com",
        "tktOwnerLastName": "Smith",
        "tktOwnerName": "Alice",
        "tktOwnerName2": "Smith",
        "tktState": "not_used",
        "tktPrice": 12000,
        "tktPriceProducer": 10000,
        "tktRrppComision": 2000,
        "tktType": "ABC123",
        "tktSoldOut": null,
        "tktTags": null,
        "tktOutdateDate": "2024-06-15T08:00:00.000Z",
        "tktComboSubTickets": [],
        "createEmail": "admin@example.com",
        "createName": "Admin",
        "createLastName": "User",
        "rrppEmail": "rrpp@example.com",
        "rrppName": "Bob",
        "rrppLastName": "Johnson",
        "producerEmail": null,
        "producerName": null,
        "producerLastName": null,
        "tktInvitationDU": null,
        "tktControlUser": null,
        "tktControlUserEmail": null,
        "tktControlTime": null,
        "tktControlType": null
      }
    ],
    "size": 2
  },
  {
    "items": [
      {
        "id": "JKL345",
        "tktBuyDate": "2024-05-16T22:32:51.000Z",
        "tktBuyOperationId": "MNO678",
        "tktBuyerDU": "135792",
        "tktBuyerEmail": "customer@example.com",
        "tktBuyerName": "David",
        "tktBuyerLastName": "Brown",
        "tktBuyerName2": "3",
        "tktCategory": "General",
        "tktColor": "#00ff00",
        "tktDisplayName": "General Admission",
        "tktOrigin": "exchange",
        "tktOwnerDU": "246813",
        "tktOwnerEmail": "david.brown@example.com",
        "tktOwnerLastName": "Brown",
        "tktOwnerName": "David",
        "tktOwnerName2": "Brown",
        "tktState": "already_used",
        "tktPrice": 8000,
        "tktPriceProducer": 6000,
        "tktRrppComision": 2000,
        "tktType": "DEF456",
        "tktSoldOut": null,
        "tktTags": null,
        "tktOutdateDate": "2024-07-01T08:00:00.000Z",
        "tktComboSubTickets": [],
        "createEmail": "admin@example.com",
        "createName": "Admin",
        "createLastName": "User",
        "rrppEmail": "rrpp@example.com",
        "rrppName": "Sarah",
        "rrppLastName": "Taylor",
        "producerEmail": null,
        "producerName": null,
        "producerLastName": null,
        "tktInvitationDU": null,
        "tktControlUser": null,
        "tktControlUserEmail": null,
        "tktControlTime": null,
        "tktControlType": null
      }
    ],
    "size": 4
  },
  {
    "items": [
      {
        "id": "PQR901",
        "tktBuyDate": "2024-05-17T22:32:51.000Z",
        "tktBuyOperationId": "STU234",
        "tktBuyerDU": "246813",
        "tktBuyerEmail": "info@example.com",
        "tktBuyerName": "Emma",
        "tktBuyerLastName": "Wilson",
        "tktBuyerName2": "4",
        "tktCategory": "Entrada",
        "tktColor": "#ff00ff",
        "tktDisplayName": "Entrada General",
        "tktOrigin": "rrppsell",
        "tktOwnerDU": "579135",
        "tktOwnerEmail": "emma.wilson@example.com",
        "tktOwnerLastName": "Wilson",
        "tktOwnerName": "Emma",
        "tktOwnerName2": "Wilson",
        "tktState": "already_used",
        "tktPrice": 6000,
        "tktPriceProducer": 4000,
        "tktRrppComision": 2000,
        "tktType": "GHI789",
        "tktSoldOut": null,
        "tktTags": null,
        "tktOutdateDate": "2024-08-15T08:00:00.000Z",
        "tktComboSubTickets": [],
        "createEmail": "admin@example.com",
        "createName": "Admin",
        "createLastName": "User",
        "rrppEmail": "rrpp@example.com",
        "rrppName": "Michael",
        "rrppLastName": "Davis",
        "producerEmail": null,
        "producerName": null,
        "producerLastName": null,
        "tktInvitationDU": null,
        "tktControlUser": null,
        "tktControlUserEmail": null,
        "tktControlTime": null,
        "tktControlType": null
      }
    ],
    "size": 5
  }
];

axiosMockAdapter.onGet('/events/tickets/list').reply(200, { tickets });

axiosMockAdapter.onPost('/events/tickets/list').reply((config) => {
  const ticketData = JSON.parse(config.data);
  tickets.push(ticketData);
  return [201, ticketData];
});

axiosMockAdapter.onDelete('/events/tickets/list/delete').reply((config) => {
  const ticketId = config.params.ticketId;

  const ticketIndex = tickets.findIndex(ticket => 
    ticket.items.some(item => item.id === ticketId)
  );

  if (ticketIndex !== -1) {
    const itemIndex = tickets[ticketIndex].items.findIndex(item => item.id === ticketId);
    tickets.splice(itemIndex, 1);
    return [200, { message: 'Ticket deleted successfully' }, { status: 200, statusText: 'OK' }];
  } else {
    return [404, { error: 'Ticket not found' }, { status: 404, statusText: 'Not Found', data: { message: 'Ticket not found' } }];
  }
});