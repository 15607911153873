import { createSlice } from '@reduxjs/toolkit';
import { Gender } from 'src/models/gender';

interface GenderState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  genders: Gender[];
  activeGender: Gender | {};
  genderPaginated: Gender[];
  page: number;
  limit: number;
  changeGender: null;
}

const initialState: GenderState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  genders: [],
  activeGender: {},
  genderPaginated: [],
  page: 0,
  limit: 25,
  changeGender: null
};

export const genderSlice = createSlice({
  name: 'gender',
  initialState,
  reducers: {
    startLoadingGenders: (state) => {
      state.isLoading = true;
    },
    setGenders: (state, action) => {
      state.isLoading = false;
      state.genders = [...action.payload];
    },
    setGendersPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.genderPaginated = action.payload.genders.data;
    },
    setChangeGender: (state, action) => {
      state.changeGender = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    stopLoadingGenders: (state) => {
      state.isLoading = false;
    }
  }
});

export const {
  startLoadingGenders,
  setGenders,
  setGendersPaginated,
  setError,
  setChangeGender,
  stopLoadingGenders
} = genderSlice.actions;
