import { createSlice } from '@reduxjs/toolkit';
import { StatisticByRRPP } from 'src/models/statistic';

interface StatisticByRRPPState {
  isLoading: boolean;
  isLoadingDetails: boolean;
  isErrorMessage: string;
  statusCode: number;
  statisticsByRRPP: StatisticByRRPP[];
  activeStatisticByRRPP: StatisticByRRPP | {};
  statisticByRRPPPaginated: StatisticByRRPP[];
  pageRrpp: number;
  limitRrpp: number;
  changeStsRrpp: null;
  filters: any;
  details: any;
}

const initialState: StatisticByRRPPState = {
  isLoading: false,
  isLoadingDetails: false,
  statusCode: 201,
  isErrorMessage: '',
  statisticsByRRPP: [],
  activeStatisticByRRPP: {},
  statisticByRRPPPaginated: [],
  pageRrpp: 0,
  limitRrpp: 25,
  changeStsRrpp: null,
  filters: {
    filter: {
      tktDates: {
        from: '',
        to: ''
      },
      place: '',
      state: '',
      onlyGift: false,
      user: '',
      tags: []
    },
    order: {
      orderBy: 'Qt',
      orderDesc: true
    }
  },
  details: [],
};

export const statisticByRRPPSlice = createSlice({
  name: 'statisticByRRPP',
  initialState,
  reducers: {
    startLoadingStatisticsByRRPP: (state) => {
      state.isLoading = true;
    },
    setStatisticsByRRPP: (state, action) => {
      state.isLoading = false;
      state.statisticsByRRPP = action.payload;
    },
    setStatisticsByRRPPPaginated: (state, action) => {
      state.isLoading = false;
      state.pageRrpp = action.payload.page;
      state.statisticByRRPPPaginated = action.payload.statisticsByRRPP.data;
    },
    setChangeStsRrpp: (state, action) => {
      state.changeStsRrpp = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    startLoadingDetails: (state) => {
      state.isLoadingDetails = true;
    },
    setDetails: (state, action) => {
      state.details = action.payload;
      state.isLoadingDetails = false;
    },
    stopLoadingStatisticsByRRPP: (state) => {
      state.isLoading = false;
    },
    stopLoadingDetails: (state) => {
      state.isLoadingDetails = false;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    }
  }
});

export const {
  startLoadingStatisticsByRRPP,
  setStatisticsByRRPP,
  setStatisticsByRRPPPaginated,
  setError,
  setChangeStsRrpp,
  setFilters,
  setDetails,
  stopLoadingStatisticsByRRPP,
  startLoadingDetails,
  stopLoadingDetails
} = statisticByRRPPSlice.actions;