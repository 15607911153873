import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Footer from 'src/components/Footer';
import { Grid, Typography } from '@mui/material';
import configMapper from 'src/config/config.mapper';

const Dashboard: FC = () => {
  return (
    <>
      <Helmet>
        <title>{`BO ${configMapper.APP_DISPLAY_NAME}`}</title>
      </Helmet>
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {`Backoffice ${configMapper.APP_DISPLAY_NAME}`}
            </Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Footer />
    </>
  );
};

export default Dashboard;
