import axiosMockAdapter from 'src/utils/mock';
import type { TicketType } from 'src/models/ticket';

const urlMatch = new RegExp(/^\/api\/ticketsType\//); 

let ticketsType: TicketType[] = [
    {
      "tktEndDate": {
        "_seconds": 1711940400,
        "_nanoseconds": 0
      },
      "tktDisplayName": "Ticket Keops Entrada General",
      "tktSellRrppEnable": true,
      "tktCategory": "Ticket",
      "tktPriceProducer": 1000,
      "tktCreateUser": "superadm@mailinator.com",
      "tktIconFamily": "MaterialIcons",
      "tktLoyaltiPoints": 1000,
      "tktLastUpdateUser": "superadm@mailinator.com",
      "tktOutdateDate": {
        "_seconds": 1704175200,
        "_nanoseconds": 0
      },
      "tktIcon": "Audiotrack",
      "tktQtOriginal": 2000,
      "tktProducerName": "Keops Entrada General ",
      "tktColor": "#bb2fee",
      "tktVisible": true,
      "tktMaxQtBuy": 10,
      "tktSoldOut": false,
      "tktTags": [
        {
          "isTagVisible": true,
          "tagColor": "#1fe022",
          "id": "vljlZ38kV0qalFulP3Ax",
          "tagName": "VERIFICADO"
        },
        {
          "isTagVisible": true,
          "tagColor": "#000000",
          "id": "pQRhqfJNzEDPkgsnJ7Jn",
          "tagName": "TEST"
        }
      ],
      "tktLastUpdate": {
        "_seconds": 1709755858,
        "_nanoseconds": 462000000
      },
      "tktInitDate": {
        "_seconds": 1703473200,
        "_nanoseconds": 0
      },
      "tktQt": 1800,
      "tktName": "Entrada General",
      "tktCreated": {
        "_seconds": 1709755858,
        "_nanoseconds": 462000000
      },
      "tktComboSubTickets": [],
      "tktPrice": 10000,
      "id": "5MjCqxwQlsTuNYiibKxj"
    },
    {
      "tktEndDate": {
        "_seconds": 1711940400,
        "_nanoseconds": 0
      },
      "tktDisplayName": "Ticket Keops Entrada VIP",
      "tktSellRrppEnable": false,
      "tktCategory": "Ticket",
      "tktCreateUser": "superadm@mailinator.com",
      "tktIconFamily": "FontAwesome",
      "tktLastUpdateUser": "superadm@mailinator.com",
      "tktOutdateDate": {
        "_seconds": 1704175200,
        "_nanoseconds": 0
      },
      "tktIcon": "space-shuttle",
      "tktQtOriginal": 100,
      "tktProducerName": "Keops Entrada VIP",
      "tktVisible": true,
      "tktMaxQtBuy": 10,
      "tktSoldOut": false,
      "tktTags": [
        {
          "isTagVisible": true,
          "tagColor": "#1fe022",
          "id": "vljlZ38kV0qalFulP3Ax",
          "tagName": "VERIFICADO"
        },
        {
          "isTagVisible": true,
          "tagColor": "#000000",
          "id": "pQRhqfJNzEDPkgsnJ7Jn",
          "tagName": "TEST"
        }
      ],
      "tktInitDate": {
        "_seconds": 1703473200,
        "_nanoseconds": 0
      },
      "tktQt": 90,
      "tktName": "Entrada VIP",
      "tktCreated": {
        "_seconds": 1709756017,
        "_nanoseconds": 887000000
      },
      "tktComboSubTickets": [],
      "tktColor": "#df4a3a",
      "tktLoyaltiPoints": 2000,
      "tktLastUpdate": {
        "_seconds": 1709756307,
        "_nanoseconds": 96000000
      },
      "tktPriceProducer": 2000,
      "tktPrice": 20000,
      "id": "dC5rWQHmO0a6ngS2thmz"
    }
  ];

axiosMockAdapter.onGet('/api/ticketsType').reply(200, { ticketsType });

axiosMockAdapter.onPost('/api/ticketsType').reply((config) => {
  const ticketTypeData = JSON.parse(config.data);
  ticketsType.push(ticketTypeData);
  return [201, ticketTypeData];
});

axiosMockAdapter.onPut(urlMatch).reply((config) => {
  const ticketTypeData = JSON.parse(config.data);
  const index = ticketsType.findIndex((ticket) => ticket.id === ticketTypeData.id);

  if (index !== -1) {
    ticketsType[index] = ticketTypeData;

    return [200, ticketTypeData];
  } else {
    return [404, { error: 'Tipo de ticket no encontrado' }, { status: 404, statusText: 'Not Found', data: { message: 'Tipo de ticket no encontrado' } }];
  }
});

axiosMockAdapter.onDelete(urlMatch).reply((config) => {

  console.log('config.data: ', config.data);
  const ticketTypeDelete = JSON.parse(config.data);
  const index = ticketsType.findIndex((tag) => tag.id === ticketTypeDelete.id);

  if (index !== -1) {
    ticketsType.splice(index, 1);

    return [200, { message: 'Tipo de ticket eliminado correctamente' }, { status: 200, statusText: 'OK' }];
  } else {
    return [404, { error: 'Tipo de ticket no encontrado' }, { status: 404, statusText: 'Not Found', data: { message: 'Tipo de ticket no encontrado' } }];
  }
});