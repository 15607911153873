import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {

  return (
    <>
      <Sidebar />
      <Box>
        <Header />
        <Outlet />
      </Box>
    </>
  );
};

export default SidebarLayout;
