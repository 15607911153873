import { createSlice } from '@reduxjs/toolkit';
import { TicketType } from 'src/models/ticket';
import { TicketProcess } from 'src/models/ticketType';

interface TicketTypeState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  ticketsType: TicketType[];
  ticketsTypePaginated: TicketType[];
  page: number;
  limit: number;
  changeTicketType: null;
  processId: number;
  ticketProcess: TicketProcess | null;
  registeredTickets: any;
  unregisteredTickets: any;
  totalRegisteredTickets: number;
  totalUnregisteredTickets: number;
  quantityRegisteredTickets: number;
  quantityUnregisteredTickets: number;
  selectedComboTicketType: TicketType[];
}

const initialState: TicketTypeState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  ticketsType: [],
  ticketsTypePaginated: [],
  page: 0,
  limit: 25,
  changeTicketType: null,
  processId: null,
  ticketProcess: null,
  registeredTickets: [],
  unregisteredTickets: [],
  totalRegisteredTickets: 0,
  totalUnregisteredTickets: 0,
  quantityRegisteredTickets: 0,
  quantityUnregisteredTickets: 0,
  selectedComboTicketType: []
};

export const ticketTypeSlice = createSlice({
  name: 'ticketType',
  initialState,
  reducers: {
    startLoadingTicketsType: (state) => {
      state.isLoading = true;
    },
    stopLoadingTicketsType: (state) => {
      state.isLoading = false;
    },
    setTicketsType: (state, action) => {
      state.ticketsType = [...action.payload];
      state.isLoading = false;
    },
    setTicketsTypePaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.ticketsTypePaginated = action.payload.ticketsType.data;
    },
    setChangeTicketType: (state, action) => {
      state.changeTicketType = action.payload;
      state.isLoading = false;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
      state.isLoading = false;
    },
    setProcessId: (state, action) => {
      state.processId = action.payload;
      state.isLoading = false;
    },
    setTicketProcess: (state, action) => {
      state.ticketProcess = action.payload;
      state.isLoading = false;
    },
    setRegisteredTickets: (state, action) => {
      state.registeredTickets = action.payload;
      state.isLoading = false;
    },
    setUnregisteredTickets: (state, action) => {
      state.unregisteredTickets = action.payload;
      state.isLoading = false;
    },
    setClearTickets: (state) => {
      state.registeredTickets = [];
      state.unregisteredTickets = [];
      state.totalRegisteredTickets = 0;
      state.totalUnregisteredTickets = 0;
      state.quantityRegisteredTickets = 0;
      state.quantityUnregisteredTickets = 0;
      state.isLoading = false;
    },
    setTotalTickets: (state, action) => {
      state.totalRegisteredTickets = action.payload.totalRegisteredTickets;
      state.totalUnregisteredTickets = action.payload.totalUnregisteredTickets;
    },
    setClearErrorMessage: (state) => {
      state.isErrorMessage = '';
    },
    setQuantityTickets: (state, action) => {
      state.quantityRegisteredTickets =
        action.payload.quantityRegisteredTickets;
      state.quantityUnregisteredTickets =
        action.payload.quantityUnregisteredTickets;
    },
    setSelectedComboTicketType: (state, action) => {
      state.selectedComboTicketType = action.payload;
    }
  }
});

export const {
  startLoadingTicketsType,
  setTicketsType,
  setTicketsTypePaginated,
  setError,
  setChangeTicketType,
  setProcessId,
  setTicketProcess,
  setRegisteredTickets,
  setUnregisteredTickets,
  setClearTickets,
  stopLoadingTicketsType,
  setClearErrorMessage,
  setTotalTickets,
  setQuantityTickets,
  setSelectedComboTicketType
} = ticketTypeSlice.actions;
