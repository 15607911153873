import { createSlice } from '@reduxjs/toolkit';
import { Month } from 'src/models/month';

interface MonthState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  months: Month[];
  activeMonth: Month | {};
  monthPaginated: Month[];
  page: number;
  limit: number;
  changeMonth: null;
}

const initialState: MonthState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  months: [],
  activeMonth: {},
  monthPaginated: [],
  page: 0,
  limit: 25,
  changeMonth: null
};

export const monthSlice = createSlice({
  name: 'month',
  initialState,
  reducers: {
    startLoadingMonths: (state) => {
      state.isLoading = true;
    },
    setMonths: (state, action) => {
      state.isLoading = false;
      state.months = [...action.payload];
    },
    setMonthsPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.monthPaginated = action.payload.months.data;
    },
    setChangeMonth: (state, action) => {
      state.changeMonth = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    stopLoadingMonths: (state) => {
      state.isLoading = false;
    }
  }
});

export const {
  startLoadingMonths,
  setMonths,
  setMonthsPaginated,
  setError,
  setChangeMonth,
  stopLoadingMonths
} = monthSlice.actions;
