import { RouteObject } from 'react-router';
import Authenticated from 'src/components/Authenticated';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import managementRoutes from './management';
import baseRoutes from './base';
import accountRoutes from './account';
import Dashboard from 'src/content/dashboard';
import RoleBasedRoute from './routesConstants';
import { Outlet } from 'react-router-dom';
import { rolesApp } from 'src/utils/rolesConstants';

const router: RouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'dashboard',
    element: (
      <Authenticated>
        <SidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />
      },
      {
        path: 'management',
        element: (
            <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR, rolesApp.POS, rolesApp.ADMIN]} element={<Outlet />} />
          ),
        children: managementRoutes
      }
    ]
  }
];

export default router;
