import { createSlice } from '@reduxjs/toolkit';
import { Tag } from 'src/models/tag';

interface TagState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  tags: Tag[];
  activeTag: Tag | {};
  tagPaginated: Tag[];
  page: number;
  limit: number;
  lastVisible: any;
  changeTag: null;
  totalTags: number;
}

const initialState: TagState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  tags: [],
  activeTag: {},
  tagPaginated: [],
  page: 0,
  limit: 25,
  lastVisible: null,
  changeTag: null,
  totalTags: 0
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    startLoadingTags: (state) => {
      state.isLoading = true;
    },
    stopLoadingTags: (state) => {
      state.isLoading = false;
    },
    setTags: (state, action) => {
      state.isLoading = false;
      state.tags = [...action.payload];
    },
    setTagsPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.tagPaginated = action.payload.tags;
      state.lastVisible = action.payload.lastVisible;
      state.totalTags = action.payload.totalTags;
    },
    setChangeTag: (state, action) => {
      state.changeTag = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    }
  }
});

export const {
  startLoadingTags,
  stopLoadingTags,
  setTagsPaginated,
  setError,
  setChangeTag,
  setTags
} = tagSlice.actions;
