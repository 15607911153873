import axiosMockAdapter from "src/utils/mock";
import { Gender } from "src/models/gender";

const genders: Gender[] = [
    {
      "id": "1",
      "name": "Male"
    },
    {
      "id": "2",
      "name": "Female"
    },
    {
      "id": "3",
      "name": "All"
    }
  ];
  axiosMockAdapter.onGet('/api/genders').reply(200, { genders });