import {
  Box,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  styled
} from '@mui/material';
import LogoIcon from 'src/common/assets/images/logoBlack.png';
import configMapper from 'src/config/config.mapper';
  
  const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.colors.alpha.trueWhite[100],
      color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 'bold',
      borderRadius: theme.general.borderRadiusSm,
      boxShadow:
        '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.colors.alpha.trueWhite[100]
    }
  }));
  
  function LogoSplash() {
    return (
      <TooltipWrapper title={`${configMapper.APP_DISPLAY_NAME} Backoffice`} arrow>
        <Box
          component="img"
          src={LogoIcon}
          alt="Logo"
          sx={{
            width: { xs: '65%', sm: '65%', md: '80%' },
            height: 'auto',
            maxWidth: 300,
            display: 'block',
            margin: '0 auto'
          }}
        />
      </TooltipWrapper>
    );
  }
  
  export default LogoSplash;
  