import { createSlice } from '@reduxjs/toolkit';
import { StatisticByEvent } from 'src/models/statistic';

interface StatisticByEventState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  statisticsByEvent: StatisticByEvent[];
  activeStatisticByEvent: StatisticByEvent | {};
  statisticsByEventPaginated: StatisticByEvent[];
  pageEvt: number;
  limitEvt: number;
  changeStsEvent: null;
  filters: any;
  details: any;
}

const initialState: StatisticByEventState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  statisticsByEvent: [],
  activeStatisticByEvent: {},
  statisticsByEventPaginated: [],
  pageEvt: 0,
  limitEvt: 25,
  changeStsEvent: null,
  filters: {
    filter: {
      tktDates: {
        from: '',
        to: ''
      },
      place: '',
      state: '',
      onlyGift: false,
      user: '',
      tags: []
    },
    order: {
      orderBy: 'Qt',
      orderDesc: true
    }
  },
  details: [],
};

export const statisticByEventSlice = createSlice({
  name: 'statisticByEvent',
  initialState,
  reducers: {
    startLoadingStatisticsByEvent: (state) => {
      state.isLoading = true;
    },
    setStatisticsByEvent: (state, action) => {
      state.isLoading = false;
      state.statisticsByEvent = action.payload;
    },
    setStatisticsByEventPaginated: (state, action) => {
      state.isLoading = false;
      state.pageEvt = action.payload.page;
      state.statisticsByEventPaginated = action.payload.statisticsByEvent.data;
    },
    setchangeStsEvent: (state, action) => {
      state.changeStsEvent = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setDetails: (state, action) => {
      state.details = action.payload;
    },
    stopLoadingStatisticsByEvent: (state) => {
      state.isLoading = false;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    }
  }
});

export const {
  startLoadingStatisticsByEvent,
  setStatisticsByEvent,
  setStatisticsByEventPaginated,
  setError,
  setchangeStsEvent,
  setFilters,
  setDetails,
  stopLoadingStatisticsByEvent
} = statisticByEventSlice.actions;